<template>
    <div>
        仓库管理-设备管理
    </div>
</template>

<script>
export default {
    name: 'DeviceManagement',
    components: {}
}
</script>
 
<style scoped></style>