<template>
  <ul class="Menus">
    <template v-for="(item, index) in MenuList">
      <li
        v-if="item._Visible"
        :class="{ MenuItem: true, expanded: item._isExpanded }"
        @click="toggleExpand(index)"
        :key="item._id"
      >
        <img
          class="MenuItemIcon"
          :src="'http://112.124.26.92:3001/' + item._MenuItemIcon"
        />
        <span>{{ item._title }}</span>
        <img
          :class="{ MenuItemArrow: true, expanded: item._isExpanded }"
          src="../../assets/icon/list.png"
        />
      </li>
      <transition name="MenuChild" :key="item._id">
        <ul
          v-if="item._Visible"
          v-show="item._isExpanded"
          :class="{ MenuChilds: true }"
        >
          <template v-for="child in item._MenuChilds">
            <li
              v-if="child._isShow"
              :key="child._id"
              :class="{
                Focus:
                  child._route != '' && $route.name === child._route
                    ? true
                    : false,
              }"
              @click="_Focus($event, child._route)"
            >
              {{ child._SubTag }}
            </li>
          </template>
        </ul>
      </transition>
    </template>
  </ul>
</template>

<script>
export default {
  name: "MenuItem",
  data() {
    return {
      // 上一个节点用于展开折叠侧边导航栏
      PreviousNode: "",
      MenuList:[],
    };
  },
  methods: {
    // 请求侧边栏菜单列表
    // 后期根据登陆拥有不同权限用户发送不同请求获取列表信息"auth"
    /* GetMenuList(auth) {
            switch (auth) {
                case "g超级管理员权限校验码":
                    // 执行不同请求
                    this.axios.get('api/MenuList/root').then(response => {
                        this.MenuList = response.data
                        console.log(response.data);
                    })
                    break;

                default:
                    break;
            }
        }, */
    GetMenuList() {
      if (localStorage.getItem("MenuList")) {
        this.MenuList = JSON.parse(localStorage.getItem("MenuList"))
        this.AutoExpand(this.$route.name);
      }else{
        this.axios
          .get("http://112.124.26.92:3001/MenuList")
          .then((response) => {
            localStorage.setItem("MenuList", JSON.stringify(response.data));
            this.MenuList = response.data;
            // 根据路由自动展开对应菜单列表
            console.log("请求了一次菜单列表");
            if (this.MenuList.length != 0) {
              this.AutoExpand(this.$route.name);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },

    // 菜单的展开收缩控制
    toggleExpand(index) {
      // 先判断当前目标展开状态，如果展开的话直接将其关闭
      if (this.MenuList[index]._isExpanded) {
        this.MenuList[index]._isExpanded = !this.MenuList[index]._isExpanded;
      } else {
        // 遍历对象中的所有菜单
        this.MenuList.forEach((e) => {
          // 判断所有菜单展开状态，如果有展开的菜单将其关闭
          if (e._isExpanded) {
            e._isExpanded = !e._isExpanded;
          }
        });
        // 检查完后再展开目标菜单
        this.MenuList[index]._isExpanded = !this.MenuList[index]._isExpanded;
      }
    },
    // 点击子菜单后触发
    _Focus(e, route) {
      this.$router.push({
        name: route,
      });
    },
    // 根据路由情况自动展开导航菜单
    AutoExpand(route) {
      for (let i = 0; i < this.MenuList.length; i++) {
        const menu = this.MenuList[i];
        for (let j = 0; j < menu._MenuChilds.length; j++) {
          const child = menu._MenuChilds[j];
          if (child._route === route) {
            menu._isExpanded = true;
            return;
          }
        }
      }
    },
  },
  mounted() {
    // 获取菜单项
    this.GetMenuList();
  },
};
</script>

<style scoped lang="less">
.Menus {
  width: 100%;
  margin-top: 10px;

  & > .MenuItem {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    margin: 5px 0 1px 0;
    /* 文字间距 */
    letter-spacing: 1px;
    transition: background-color 0.3s linear 0s;

    &:hover {
      background-color: #483d8b;
    }

    & > .MenuItemIcon {
      width: 24px;
      height: 24px;
    }

    & > span {
      font-size: 17px;
      font-weight: bold;
    }

    & > .MenuItemArrow {
      width: 15px;
      height: 15px;
    }
  }

  & > .MenuChilds {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background-color: #262626;
    overflow: hidden;

    & > li {
      width: 100%;
      height: 50px;
      border-radius: 5px;
      font-size: 0.8rem;
      line-height: 50px;
      padding-left: 42%;
      /* 文字间距 */
      letter-spacing: 1px;
      transition: background-color 0.3s linear 0s;

      &:hover {
        background-color: #483d8b;
      }

      & > img {
        width: 10px;
        height: 10px;
      }
    }
  }
}

// 添加选中样式
.Focus {
  color: #55e676;
  font-size: 15px;
  font-weight: bold;
  background-color: #483d8b;

  &::after {
    content: "  🍊";
  }
}

// 展开收缩动画实现
// 箭头
.MenuItemArrow {
  transform: rotate(0deg);
  transition: transform 0.3s ease-out 0.1s;
}

.MenuItemArrow.expanded {
  transform: rotate(-90deg);
  transition: transform 0.3s ease-in;
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.MenuChild-enter-active {
  transition: all 0.5s ease-in;
}

.MenuChild-leave-active {
  transition: all 0.5s ease-out -0.1s;
}

.MenuChild-enter,
.MenuChild-leave-to {
  max-height: 0px;
}

.MenuChild-enter-to,
.MenuChild-leave {
  max-height: 500px;
}

// 动画结束
</style>