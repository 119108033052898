<template>
  <el-empty
    class="NotFound"
    :description="`404 Not Found : 找不到 “${$route.path}” 页面`"
  >
    <!-- '`404 Unable to find the "${$route.path}" page`' -->
    <el-button type="primary" @click="HomePage">返回主页</el-button>
  </el-empty>
</template>

<script>
export default {
  name: "NotFound",
  methods: {
    HomePage() {
      this.$router.push({
        name: "MainPage",
      });
    },
  },
};
</script>
 
<style scoped lang="less">
.NotFound {
  height: 100%;
}
</style>