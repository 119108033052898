<template>
    <div>
        仓库管理-出库管理
    </div>
</template>

<script>
export default {
    name: 'OutboundManagement',
    components: {}
}
</script>
 
<style scoped></style>