<template>
  <div class="Duty">
    <el-calendar>
      <template slot="dateCell" slot-scope="{ data }">
        <p :class="data.isSelected ? 'is-selected' : ''">
          {{ data.day.split("-").slice(1).join("-") }}
          <!-- {{ data.isSelected ? "🍊" : "" }} -->
        </p>
        <span>值班人：{{ name[Number(data.day.split("-").slice(2))] }}</span>
      </template>
    </el-calendar>
  </div>
</template>

<script>
export default {
  name: "DutyTable",
  data() {
    return {
      name: [
        "孟欣嘉",
        "任琼岚",
        "邰华翰",
        "能思雁",
        "空昂雄",
        "郜山雁",
        "贾阳州",
        "景夏河",
        "许康宁",
        "冯恨寒",
        "乔俊茂",
        "勾访卉",
        "夏宏义",
        "班梓云",
        "汤宏伟",
        "劳曼云",
        "钭光济",
        "漕茹雪",
        "毛博涛",
        "潘丝祺",
        "牛建德",
        "方闲丽",
        "通理全",
        "从问梅",
        "谢君浩",
        "殳采波",
        "林意致",
        "芮智菱",
        "班梓云",
        "汤宏伟",
        "劳曼云",
        "钭光济",
        "漕茹雪",
        "毛博涛",
        "潘丝祺",
        "牛建德",
        "方闲丽",
        "通理全",
        "从问梅",
        "谢君浩",
        "殳采波",
        "林意致",
        "芮智菱",
      ],
    };
  },
};
</script>

<style scoped lang="less">
.Duty {
  width: 100%;
  height: 100%;
}

/deep/ .el-calendar {
  font-size: 14px;
  .el-calendar-day {
    height: 108px !important;
    text-align: center;
  }
  .el-calendar__header {
    justify-content: space-between;
  }
  .is-selected {
    background-color: #409eff;
    color: #252525;
  }
  .el-calendar-day:hover {
    background-color: #409eff;
    color: #252525;
  }
}
</style>