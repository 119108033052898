<template>
  <!-- 考勤签到-考勤记录 -->
  <DutyLog :DutyList="DutyList">
    <el-row class="FormControlBottom" slot="FormControlBottom">
      <!-- 分页 -->
      <el-pagination
        :page-size="13"
        layout=" total, prev, pager, next, jumper"
        :total="DutyList.length"
      >
      </el-pagination>
    </el-row>
  </DutyLog>
</template>

<script>
import DutyLog from "../DutyLog";
export default {
  name: "AttendanceRecord",
  components: { DutyLog },
  data() {
    return {
      DutyList: [
        { UserName: "张三", UserID: 12138, qj: 1, cd: 2, zt: 3, kg: 4, qk: 5 },
        { UserName: "李四", UserID: 12138, qj: 2, cd: 2, zt: 3, kg: 4, qk: 5 },
        { UserName: "王五", UserID: 12138, qj: 3, cd: 2, zt: 3, kg: 4, qk: 5 },
        { UserName: "赵六", UserID: 12138, qj: 4, cd: 2, zt: 3, kg: 4, qk: 5 },
        { UserName: "钱七", UserID: 12138, qj: 5, cd: 2, zt: 3, kg: 4, qk: 5 },
        { UserName: "孙九", UserID: 12138, qj: 6, cd: 2, zt: 3, kg: 4, qk: 5 },
        { UserName: "李十针", UserID: 12138, qj: 7, cd: 2, zt: 3, kg: 4, qk: 5 },
        { UserName: "周扒皮", UserID: 12138, qj: 8, cd: 2, zt: 3, kg: 4, qk: 5 },
        { UserName: "吴彦祖", UserID: 12138, qj: 9, cd: 2, zt: 3, kg: 4, qk: 5 },
        { UserName: "正方形", UserID: 12138, qj: 10, cd: 2, zt: 3, kg: 4, qk: 5 },
        { UserName: "王老五", UserID: 12138, qj: 11, cd: 2, zt: 3, kg: 4, qk: 5 },
        { UserName: "冯小刚", UserID: 12138, qj: 12, cd: 2, zt: 3, kg: 4, qk: 5 },
        { UserName: "陈醋", UserID: 12138, qj: 13, cd: 2, zt: 3, kg: 4, qk: 5 },
      ],
    };
  },
};
</script>

<style lang="less">
@BGC-day-dark: #262626;
@BGC-day-Light: #323232;

.FormControlBottom {
  height: 50px;
  padding: 0 10px 0 10px;
  border-radius: 0 0 5px 5px;
  // 下面这行删除
  background-color: @BGC-day-Light;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>