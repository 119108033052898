<template>
  <el-row class="RepairList">
    <!-- 表单控制区 -->
    <el-row class="FormControlTop" type="flex" justify="space-between">
      <el-col :span="6">
        <slot name="SearchInput"></slot>
      </el-col>
      <el-col :span="4" class="buttons">
        <el-button
          size="small"
          :disabled="DelSelectBtn"
          type="danger"
          @click="DeleteSelect"
          >批量删除</el-button
        >
      </el-col>
    </el-row>
    <!-- 表单区 -->
    <el-table
      :data="DutyList"
      @selection-change="handleSelectionChange"
      ref="table"
      class="FormInfomation"
      border
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      stripe
      highlight-selection-row
      :max-height="WindowSize.height - 236"
    >
      <!-- 选择框 -->
      <el-table-column fixed type="selection" width="55"></el-table-column>
      <el-table-column prop="UserName" label="姓名" width="120">
      </el-table-column>
      <el-table-column prop="UserID" label="学号/工号" width="120">
      </el-table-column>
      <el-table-column prop="qj" label="请假次数" width="120">
      </el-table-column>
      <el-table-column prop="cd" label="迟到次数" width="120">
      </el-table-column>
      <el-table-column prop="zt" label="早退次数" width="120">
      </el-table-column>
      <el-table-column prop="kg" label="矿工次数" width="120">
      </el-table-column>
      <el-table-column prop="qk" label="值班缺卡次数" width="120">
      </el-table-column>
    </el-table>
    <slot name="FormControlBottom"></slot>
  </el-row>
</template>

<script>
export default {
  name: "DutyLog",
  props: {
    DutyList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // window窗口大小监测
      WindowSize: { width: 0, height: 0 },

      // 批量删除按钮禁用状态(默认禁用)
      DelSelectBtn: true,
    };
  },
  methods: {
    // 查看按钮
    ViewDetails(row) {
      this.UserDetailVisible = true;
      this.UserDetailTitle = `报修单号：${row.UserID}`;
      this.DetailList = row;
      console.log(this.DetailList);
    },
    // 报修详情显示状态转换
    ChangeUserDetailVisibleStatus(state) {
      this.UserDetailVisible = state;
    },
    // 获取main大小
    GetWindowSize() {
      this.$bus.$on("WindowSize", (data) => {
        console.log();
        this.WindowSize = data;
      });
    },

    // 多选框选择更改时
    handleSelectionChange(selection) {
      // selection包含该行所有信息
      if (selection.length === 0) {
        this.DelSelectBtn = true;
      } else {
        this.DelSelectBtn = false;
      }
    },

    // 删除选中
    DeleteSelect() {},

    // 删除单个用户
    // 确认删除
    Confirm() {},

    // 删除提示内容
    DelTipTitle() {
      return `确认删除？`;
    },
    // 取消删除
    Cancel() {
      this.$message({
        type: "info",
        message: `已取消删除`,
      });
    },

    // 获取当前时间戳
    NowTimeStamp() {
      console.log("获取了一次时间戳");
      return parseInt(new Date().getTime() / 1000);
    },
  },
  created() {
    this.GetWindowSize();
  },
  mounted() {
    this.GetWindowSize();
  },
  updated() {
    this.GetWindowSize();
  },
};
</script>

<style lang="less">
@BGC-day-dark: #262626;
@BGC-day-Light: #323232;

// 表单操作区
.FormControlTop {
  height: 50px;
  padding: 0 10px 0 10px;
  border-radius: 5px 5px 0 0;
  // 下面这行删除
  background-color: @BGC-day-Light;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & .el-select .el-input {
    margin-left: 5px;
    width: 110px;
  }

  & .input-with-select > .el-input__inner {
    min-width: 220px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

// 表单区
.FormInfomation {
  width: 100%;

  & .no-padding {
    padding: 0 !important;
  }

  & .cell {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
  }
}
</style>