<template>
  <div class="UserLogin">
    <el-form
      ref="LoginForm"
      :model="LoginForm"
      class="form-container"
      :rules="LoginFormRules"
      :status-icon="true"
    >
      <div class="LoginTitle">
        <img src="../assets/icon/zjipc_blue.png" alt="网站图标" />
        <div>欢迎登录报修系统</div>
      </div>
      <el-form-item class="LineHeight" prop="UserName">
        <el-input
          type="text"
          :maxlength="11"
          v-model="LoginForm.UserName"
          placeholder="请输入账号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="LineHeight" prop="Password">
        <el-input
          type="password"
          :maxlength="32"
          v-model="LoginForm.Password"
          show-password
          placeholder="请输入密码"
          @keyup.enter.native="SubmitForm"
          clearable
        ></el-input>
      </el-form-item>
      <div class="Operation">
        <el-checkbox v-model="SaveUserInfo">记住账号</el-checkbox>
        <el-link type="info" :underline="false">忘记密码？</el-link>
      </div>
      <el-form-item class="LineHeight">
        <div class="LoginButton">
          <el-button type="danger" @click="CleanWrite">清除</el-button>
          <el-button type="primary" @click="SubmitForm">登录</el-button>
        </div>
      </el-form-item>
      <el-form-item class="LineHeight">
        <el-button type="warning" @click="visitor">访客模式</el-button>
        <!-- <el-button type="warning">访客模式(仅查看简略信息)</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "UserLogin",
  data() {
    var ValidateUserName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("账号不能为空"));
      }
      if (value.length < 5) {
        callback(new Error("账号应大于5位"));
      } else if (value.length > 11) {
        callback(new Error("账号应小于11位"));
      } else {
        callback();
      }
    };
    var ValidatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      LoginForm: {
        UserName: localStorage.getItem("UserName") || null,
        Password: "",
      },
      // 记住账号
      SaveUserInfo: false,
      //   表单验证规则
      LoginFormRules: {
        // 用户名验证
        UserName: [{ validator: ValidateUserName, trigger: "blur" }],
        // 密码验证
        Password: [{ validator: ValidatePassword, trigger: "blur" }],
      },
    };
  },
  methods: {
    // 清除输入
    CleanWrite() {
      this.$refs.LoginForm.resetFields();
    },
    // 访客登录
    visitor() {
      this.axios.post("http://112.124.26.92:3000/user/login", {UserName:'visitor',Password:'visitor'}).then((res) => {
        // console.log(res.data);
        if (res.data.ActionType === "OK") {
          this.$store.commit("ChangeUserInfo", res.data.data);
          this.$message.success(
            `登陆成功，欢迎 "${res.data.data.UserName}" 回来！`
          );
          // 登录成功后重定向至登陆前的页面，没有的话就跳转至主页
          this.$router.push({
            path: sessionStorage.getItem("redirect") || "/",
          });
        }
      });
    },
    // 登录
    SubmitForm() {
      this.$refs.LoginForm.validate((valid) => {
        if (valid) {
          // 前端校验成功
          // 本地保存用户名
          if (this.SaveUserInfo) {
            if (
              localStorage.getItem("UserName") &&
              localStorage.getItem("UserName") === this.LoginForm.UserName
            ) {
              console.log();
            } else {
              console.log("执行了一次保存");
              localStorage.setItem("UserName", this.LoginForm.UserName);
            }
          }
          // console.log(this.LoginForm);
          // 提交表单到后台
          this.axios
            .post("http://112.124.26.92:3000/user/login", this.LoginForm)
            .then((res) => {
              if (res.data.ActionType === "OK") {
                this.$store.commit("ChangeUserInfo", res.data.data);
                this.$message.success(
                  `登陆成功，欢迎 "${res.data.data.UserName}" 回来！`
                );
                // 登录成功后重定向至登陆前的页面，没有的话就跳转至主页
                this.$router.push({
                  path: sessionStorage.getItem("redirect") || "/",
                });
              } else {
                this.$message({
                  type: "error",
                  message: "用户名或密码不正确",
                });
              }
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: err.message,
              });
            });
        } else {
          // 校验失败
          this.$message({
            showClose: true,
            message: "您的账号密码输入未通过，请根据提示检查您的输入",
            type: "error",
          });
          return false;
        }
      });
    },
  },
  //   组件内路由守卫
  beforeRouteEnter(to, from, next) {
    // 如果用户已登录仍然访问登陆页面就跳转至首页
    if (localStorage.getItem("token")) {
      next({
        path: sessionStorage.getItem("redirect") || "/",
      });
    } else {
      next();
    }
  },
};
</script>

<style scoped lang="less">
.UserLogin {
  width: 100%;
  height: 100%;
  background: no-repeat url(../assets/img/8.png);
  background-size: cover;

  & > .form-container {
    width: 360px;
    height: 400px;
    background-color: #fff;
    padding: 25px;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-flow: column;
    justify-content: space-around;

    & > .LoginTitle {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 24px;
      color: #232323;
      font-weight: bold;
      display: flex;
      flex-flow: row;
      justify-content: space-evenly;

      & > img {
        width: 48px;
        height: 48px;
      }
    }
    & .Operation {
      height: 22px;
      padding: 0 1rem;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
    }
    & .LineHeight {
      margin-bottom: 0px;

      & .LoginButton {
        display: flex;
        flex-flow: row;
        justify-content: space-around;

        button {
          width: 47%;
        }
      }
      & button {
        width: 100%;
      }
    }
  }
}
</style>