<template>
    <el-empty
    class="NotFound"
    :description="`403 抱歉，您没有权限访问此页面`">
    <!-- '`404 Unable to find the "${$route.path}" page`' -->
    <el-button type="primary" @click="HomePage">{{ s }}秒后返回主页返回主页</el-button>
  </el-empty>
</template>

<script>
export default {
  name: "NoAuth",
  data() {
    return {
        // 设置几秒后返回主页
      s: 3,
    };
  },
  methods: {
    HomePage() {
      this.$router.push({
        path: "/",
      });
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.s--;
      if (this.s <= 0) {
        this.HomePage();
      }
    }, 1000);
  },
  beforeDestroy() {
    this.timer = null;
    clearInterval(this.timer);
  },
};
</script>
 
<style scoped>
.NotFound {
  height: 100%;
}
</style>