<template>
  <span>
    <el-popover
      placement="bottom"
      width="200"
      trigger="hover"
      visible-arrow:false
      :visible-arrow="false"
    >
      <div class="LogInfo">
        <el-avatar :src="'http://112.124.26.92:3000/' + HeadIcon"></el-avatar>
        <div>
          <div>
            <span>ID: </span><span>{{ $store.state.UserInfo.UserID }}</span>
          </div>
          <el-link type="primary" @click="PersonalCenterVisible = true"
            >设置</el-link
          ><span></span><el-divider direction="vertical"></el-divider
          ><el-link @click="QuitLogin" type="danger">退出</el-link><span></span>
        </div>
      </div>
      <el-divider><i class="el-icon-orange"></i></el-divider>
      <div>待补充……</div>
      <div class="HeadIcon" slot="reference">
        <el-avatar :src="'http://112.124.26.92:3000/' + HeadIcon"></el-avatar>
        <div>
          <div class="Name">{{ Name }}</div>
          <div
            :class="{
              Role: true,
              root: RoleCode === 0,
              engineer: RoleCode === 1,
              admin: RoleCode === 2 || RoleCode === 3,
              ordinary: RoleCode === 4,
              other: RoleCode === 5 || RoleCode === '',
            }"
          >
            {{ RoleName[RoleCode] }}
          </div>
        </div>
      </div>
    </el-popover>
    <el-dialog
      top="10vh"
      width="40%"
      title="个人资料"
      :visible.sync="PersonalCenterVisible"
      class="dialogClass"
    >
      <el-descriptions :column="2" border>
        <template slot="extra">
          <el-button type="primary" size="small" v-if="!isEdit" @click="Edit"
            >编辑</el-button
          >
          <el-button type="success" size="small" v-if="isEdit" @click="Submit"
            >提交</el-button
          >
        </template>
        <el-descriptions-item :span="1">
          <template slot="label">
            <i class="el-icon-camera"></i>
            头像
          </template>
          <el-upload
            class="avatar-uploader"
            action="http://112.124.26.92:3000/user/update"
            :show-file-list="false"
            :on-change="UpStateChange"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :auto-upload="false"
          >
            <img
              v-if="SubmitInfo.UserAvatar"
              :src="SubmitInfo.UserAvatar"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <i class="el-icon-warning-outline"></i>
            Tips
          </template>
          <p class="Tips">
            Tip_1：<span
              >当前用户为“{{ ConversionJob($store.state.UserInfo.Job) }}”</span
            >
          </p>
          <p class="Tips">Tip_2：<span>用户名、ID、不可更改</span></p>
          <p class="Tips">Tip_3：<span>更换头像点击头像即可</span></p>
          <p class="Tips">
            Tip_4：<span
              >加<i class="el-icon-edit"></i> 为当前用户可编辑项目</span
            >
          </p>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            用户名
          </template>
          {{ $store.state.UserInfo.UserName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-bank-card"></i>
            用户ID
          </template>
          {{ $store.state.UserInfo.UserID }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i
              :class="
                SubmitInfo.UserGender === 0 ? 'el-icon-female' : 'el-icon-male'
              "
            ></i>
            性别
          </template>
          <el-select
            style="width: 100%"
            v-if="isEdit"
            v-model="SubmitInfo.UserGender"
            placeholder="请选择性别"
          >
            <el-option
              v-for="item in GenderOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <span v-if="!isEdit">
            {{ SubmitInfo.UserGender === 0 ? "女" : "男" }}
          </span>
          <i class="el-icon-edit" v-if="!isEdit"></i>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            年龄
          </template>
          <el-select
            style="width: 100%"
            v-if="isEdit"
            v-model="SubmitInfo.UserAge"
            placeholder="请选择"
          >
            <el-option
              v-for="item in AgeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <span v-if="!isEdit">{{ SubmitInfo.UserAge }}</span>
          <i class="el-icon-edit" v-if="!isEdit"></i>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-s-custom"></i>
            角色
          </template>
          <span>{{
            ConversionUserRole(this.$store.state.UserInfo.UserRole)
          }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-collection-tag"></i>
            用户类型
          </template>
          <span>{{ ConversionJob(this.$store.state.UserInfo.Job) }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            手机号
          </template>
          <el-input
            v-if="isEdit"
            clearable
            v-model="SubmitInfo.UserMPhoneNum"
            placeholder="请输入手机号码"
          ></el-input>
          <span v-if="!isEdit">{{ SubmitInfo.UserMPhoneNum }}</span>
          <i class="el-icon-edit" v-if="!isEdit"></i>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-phone-outline"></i>
            电话
          </template>
          <el-input
            v-if="isEdit"
            clearable
            v-model="SubmitInfo.UserPhoneNum"
            placeholder="请输入电话号码"
          ></el-input>
          <span v-if="!isEdit">{{ SubmitInfo.UserPhoneNum }}</span>
          <i class="el-icon-edit" v-if="!isEdit"></i>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-school"></i>
            校区
          </template>
          <span>{{ this.$store.state.UserInfo.Campus }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            分院
          </template>
          <span>{{ this.$store.state.UserInfo.Branch }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-s-order"></i>
            专业
          </template>
          <span>{{ this.$store.state.UserInfo.Major }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-s-flag"></i>
            班级
          </template>
          <span>{{
            this.$store.state.UserInfo.StartYear +
            this.$store.state.UserInfo.Major +
            this.$store.state.UserInfo.ClassNo +
            "班"
          }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-information"></i>
            联系地址
          </template>
          <el-cascader
            style="width: 100%"
            v-if="isEdit"
            v-model="AddressSelect"
            :options="AddressOptions"
            filterable
            clearable
          ></el-cascader>
          <span v-if="!isEdit">{{
            $store.state.UserInfo.BuildingNo +
            "号" +
            $store.state.UserInfo.Building +
            "-" +
            $store.state.UserInfo.Floor +
            "楼-" +
            $store.state.UserInfo.RoomNo +
            "室"
          }}</span>
          <i class="el-icon-edit" v-if="!isEdit"></i>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-date"></i>
            注册时间
          </template>
          {{ $store.state.UserInfo.CreateTime }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </span>
</template>

<script>
export default {
  name: "HeadIcon",
  data() {
    return {
      // 用户编辑状态
      isEdit: false,
      SubmitInfo: {
        UserAvatar:
          "http://112.124.26.92:3000/" + this.$store.state.UserInfo.UserAvatar,
        UserGender: this.$store.state.UserInfo.UserGender,
        UserAge: this.$store.state.UserInfo.UserAge,
        UserMPhoneNum: this.$store.state.UserInfo.UserMPhoneNum,
        UserPhoneNum: this.$store.state.UserInfo.UserPhoneNum,
        BuildingNo: this.$store.state.UserInfo.BuildingNo,
        Building: this.$store.state.UserInfo.Building,
        Floor: this.$store.state.UserInfo.Floor,
        RoomNo: this.$store.state.UserInfo.RoomNo,
        File: null,
      },
      // 选中班级
      ClassSelect: [
        this.$store.state.UserInfo.StartYear,
        this.$store.state.UserInfo.Major,
        this.$store.state.UserInfo.ClassNo,
      ],
      // 选中地址
      AddressSelect: [
        this.$store.state.UserInfo.Building,
        this.$store.state.UserInfo.BuildingNo,
        this.$store.state.UserInfo.Floor,
        this.$store.state.UserInfo.RoomNo,
      ],
      RuleOptions: [
        {
          value: 0,
          label: "超级管理员",
        },
        {
          value: 1,
          label: "工程师",
        },
        {
          value: 2,
          label: "老师",
        },
        {
          value: 3,
          label: "学生",
        },
        {
          value: 4,
          label: "临时用户",
        },
      ],
      // 原来叫job
      UserTypeOptions: [
        {
          value: 0,
          label: "超级管理员",
        },
        {
          value: 1,
          label: "工程师",
        },
        {
          value: 2,
          label: "网管员(办)",
        },
        {
          value: 3,
          label: "网管员(宿)",
        },
        {
          value: 4,
          label: "普通用户",
        },
        {
          value: 5,
          label: "临时用户",
        },
      ],
      CampusOptions: [
        {
          value: "梅山校区",
          label: "梅山校区",
        },
        {
          value: "镜湖校区",
          label: "镜湖校区",
        },
      ],
      BranchOptionns: [
        {
          value: "设计与艺术分院",
          label: "设计与艺术分院",
        },
        {
          value: "机电分院",
          label: "机电分院",
        },
      ],
      MajorOptions: [
        {
          value: "计算机应用技术",
          label: "计算机应用技术",
        },
        {
          value: "机电自动化",
          label: "机电自动化",
        },
        {
          value: "护理专业",
          label: "护理专业",
        },
      ],
      ClassOptions: [
        {
          value: 20,
          label: "20级",
          children: [
            {
              value: "计算机应用技术",
              label: "计算机应用技术",
              children: [
                {
                  value: 1,
                  label: "1班",
                },
                {
                  value: 2,
                  label: "2班",
                },
                {
                  value: 3,
                  label: "3班",
                },
              ],
            },
          ],
        },
      ],
      GenderOptions: [
        {
          value: 0,
          label: "女",
        },
        {
          value: 1,
          label: "男",
        },
      ],
      // 函数自动生成
      AgeOptions: [],
      AddressOptions: [
        {
          value: "教学楼",
          label: "教学楼",
          children: [
            {
              value: 1,
              label: "1号教学楼",
              children: [
                {
                  value: 1,
                  label: "1楼",
                  children: [
                    {
                      value: 101,
                      label: "101室",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],

      PersonalCenterVisible: false,
      HeadIcon:
        this.$store.state.UserInfo.UserAvatar ||
        "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
      Name: this.$store.state.UserInfo.UserName,
      RoleCode: this.$store.state.UserInfo.UserRole,
      RoleName: [
        "超管",
        "工程师",
        "网管员(办)",
        "网管员(宿)",
        "普通用户",
        "未知",
      ],
    };
  },
  methods: {
    QuitLogin() {
      localStorage.removeItem("token");
      localStorage.removeItem("vuex");
      localStorage.removeItem("MenuList");
      this.$router.push({
        path: "/UserLogin",
      });
    },
    autoAgeOptions() {
      for (let i = 0; i <= 100; i++) {
        this.AgeOptions.push({
          value: i,
          label: `${i}岁`,
        });
      }
    },
    ConversionJob(job) {
      switch (job) {
        case 0:
          return "超级管理员";
        case 1:
          return "工程师";
        case 2:
          return "网管员(办)";
        case 3:
          return "网管员(宿)";
        case 4:
          return "普通用户";
        case 5:
          return "访客账户";
        default:
          return "未知";
      }
    },
    ConversionUserRole(role) {
      switch (role) {
        case 0:
          return "超级管理员";
        case 1:
          return "工程师";
        case 2:
          return "老师";
        case 3:
          return "学生";
        case 4:
          return "访客账户";
        default:
          return "未知";
      }
    },
    // 编辑信息
    Edit() {
      this.isEdit = true;
    },
    Submit() {
      const params = new FormData();
      for (let i in this.SubmitInfo) {
        params.append(i, this.SubmitInfo[i]);
      }
      // console.log(params);
      this.axios
        .post("http://112.124.26.92:3000/user/update", params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.ActionType === "OK") {
            this.$store.commit("ChangeUserInfo", res.data.data);
            this.$message({
              type: "success",
              message: "个人信息修改成功",
            });
            this.isEdit = false;
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "个人信息修改失败" + err,
          });
        });
    },
    UpStateChange(file) {
      this.SubmitInfo.UserAvatar = URL.createObjectURL(file.raw);
      this.SubmitInfo.File = file.raw;
    },
    beforeAvatarUpload() {},
    handleAvatarSuccess() {},
  },
  mounted() {
    // 自动生成age选项
    this.autoAgeOptions();
  },
};
</script>

<style scoped lang="less">
@UploadSize: 100px;
.LogInfo {
  width: 100%;
  margin: 5px auto;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}

.HeadIcon {
  width: 155px;
  height: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;
  float: right;

  & > div {
    width: 77px;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;

    & > div {
      line-height: 25px;
      text-align: center;
      font-weight: bold;
      /* 文字间距 */
      letter-spacing: 1px;

      // 单行文本不换行多余文本显示省略号
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > .Name {
      width: 100%;
      height: 100%;
      font-size: 14px;
    }

    & > .Role {
      width: 80px;
      height: 25px;
      font-size: 14px;
      border-radius: 5px;
    }
  }
}
// 个人资料
::v-deep.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
::v-deep.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
::v-deep.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: @UploadSize;
  height: @UploadSize;
  line-height: @UploadSize;
  text-align: center;
}
.avatar {
  width: @UploadSize;
  height: @UploadSize;
  display: block;
}

::v-deep.dialogClass .el-dialog__body {
  padding: 0 20px 20px 20px;
  .el-descriptions__header {
    margin-bottom: 5px;
  }
}

.Tips {
  font-weight: bold;
  margin: 10px 0;
  & > span {
    padding: 5px;
    font-size: 12px;
    color: #797979;
  }
}
</style>