<template>
  <!-- 用户详情 -->
  <el-dialog
    top="10vh"
    :width="AlertWidth"
    class="dialogClass"
    :title="UserDetailTitle"
    :visible.sync="UserDetailVisible"
  >
    <el-descriptions :column="2" border>
      <template slot="extra">
        <el-button type="primary" size="small" v-if="!isEdit" @click="Edit"
          >编辑</el-button
        >
        <el-button type="success" size="small" v-if="isEdit" @click="Submit"
          >提交</el-button
        >
      </template>
      <el-descriptions-item :span="ColSpan">
        <template slot="label">
          <i class="el-icon-camera"></i>
          头像
        </template>
        <el-upload
          class="avatar-uploader"
          action="/admin/update"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :auto-upload="false"
        >
          <img
            v-if="DetailList.UserAvatar"
            :src="'http://112.124.26.92:3000/' + DetailList.UserAvatar"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-descriptions-item>
      <el-descriptions-item :span="ColSpan">
        <template slot="label">
          <i class="el-icon-warning-outline"></i>
          Tips
        </template>
        <p class="Tips">
          Tip_1：<span>当前用户为“{{ ConversionJob(DetailList.Job) }}”</span>
        </p>
        <p class="Tips">Tip_2：<span>用户名、ID、不可更改</span></p>
        <p class="Tips">Tip_3：<span>更换头像点击头像即可</span></p>
        <p class="Tips">
          Tip_4：<span>加<i class="el-icon-edit"></i> 是可编辑项目</span>
        </p>
      </el-descriptions-item>
      <el-descriptions-item :span="ColSpan">
        <template slot="label">
          <i class="el-icon-user"></i>
          用户名
        </template>
        {{ DetailList.UserName }}
      </el-descriptions-item>
      <el-descriptions-item  :span="ColSpan">
        <template slot="label">
          <i class="el-icon-bank-card"></i>
          用户ID
        </template>
        {{ DetailList.UserID }}
      </el-descriptions-item>
      <el-descriptions-item :span="ColSpan">
        <template slot="label">
          <i
            :class="
              SubmitInfo.UserGender === 0 ? 'el-icon-female' : 'el-icon-male'
            "
          ></i>
          性别
        </template>
        <el-select
          style="width: 100%"
          v-if="isEdit"
          v-model="SubmitInfo.UserGender"
          placeholder="请选择性别"
        >
          <el-option
            v-for="item in GenderOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span v-if="!isEdit">
          {{ DetailList.UserGender === 0 ? "女" : "男" }}
        </span>
        <i class="el-icon-edit" v-if="!isEdit"></i>
      </el-descriptions-item>
      <el-descriptions-item :span="ColSpan">
        <template slot="label">
          <i class="el-icon-user"></i>
          年龄
        </template>
        <el-select
          style="width: 100%"
          v-if="isEdit"
          v-model="SubmitInfo.UserAge"
          placeholder="请选择"
        >
          <el-option
            v-for="item in AgeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span v-if="!isEdit">{{ DetailList.UserAge }}</span>
        <i class="el-icon-edit" v-if="!isEdit"></i>
      </el-descriptions-item>
      <el-descriptions-item :span="ColSpan">
        <template slot="label">
          <i class="el-icon-s-custom"></i>
          角色
        </template>
        <el-select
          style="width: 100%"
          v-if="isEdit"
          v-model="SubmitInfo.UserRole"
          placeholder="请选择"
        >
          <el-option
            v-for="item in RuleOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span v-if="!isEdit">{{
          ConversionUserRole(DetailList.UserRole)
        }}</span>
        <i class="el-icon-edit" v-if="!isEdit"></i>
      </el-descriptions-item>
      <el-descriptions-item :span="ColSpan">
        <template slot="label">
          <i class="el-icon-collection-tag"></i>
          用户类型
        </template>
        <el-select
          style="width: 100%"
          v-if="isEdit"
          v-model="SubmitInfo.Job"
          placeholder="请选择"
        >
          <el-option
            v-for="item in UserTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span v-if="!isEdit">{{ ConversionJob(DetailList.Job) }}</span>
        <i class="el-icon-edit" v-if="!isEdit"></i>
      </el-descriptions-item>
      <el-descriptions-item :span="ColSpan">
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          手机号
        </template>
        <el-input
          v-if="isEdit"
          clearable
          v-model="SubmitInfo.UserMPhoneNum"
          placeholder="请输入手机号码"
        ></el-input>
        <span v-if="!isEdit">{{ DetailList.UserMPhoneNum }}</span>
        <i class="el-icon-edit" v-if="!isEdit"></i>
      </el-descriptions-item>
      <el-descriptions-item :span="ColSpan">
        <template slot="label">
          <i class="el-icon-phone-outline"></i>
          电话
        </template>
        <el-input
          v-if="isEdit"
          clearable
          v-model="SubmitInfo.UserPhoneNum"
          placeholder="请输入电话号码"
        ></el-input>
        <span v-if="!isEdit">{{ DetailList.UserPhoneNum }}</span>
        <i class="el-icon-edit" v-if="!isEdit"></i>
      </el-descriptions-item>
      <el-descriptions-item :span="ColSpan">
        <template slot="label">
          <i class="el-icon-school"></i>
          校区
        </template>
        <el-select
          style="width: 100%"
          v-if="isEdit"
          v-model="SubmitInfo.Campus"
          placeholder="请选择"
        >
          <el-option
            v-for="item in CampusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span v-if="!isEdit">{{ DetailList.Campus }}</span>
        <i class="el-icon-edit" v-if="!isEdit"></i>
      </el-descriptions-item>
      <el-descriptions-item :span="ColSpan">
        <template slot="label">
          <i class="el-icon-office-building"></i>
          分院
        </template>
        <el-select
          style="width: 100%"
          v-if="isEdit"
          v-model="SubmitInfo.Branch"
          placeholder="请选择"
        >
          <el-option
            v-for="item in BranchOptionns"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span v-if="!isEdit">{{ DetailList.Branch }}</span>
        <i class="el-icon-edit" v-if="!isEdit"></i>
      </el-descriptions-item>
      <el-descriptions-item :span="ColSpan">
        <template slot="label">
          <i class="el-icon-s-order"></i>
          专业
        </template>
        <el-select
          style="width: 100%"
          v-if="isEdit"
          v-model="SubmitInfo.Major"
          placeholder="请选择"
        >
          <el-option
            v-for="item in MajorOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span v-if="!isEdit">{{ DetailList.Major }}</span>
        <i class="el-icon-edit" v-if="!isEdit"></i>
      </el-descriptions-item>
      <el-descriptions-item :span="ColSpan">
        <template slot="label">
          <i class="el-icon-s-flag"></i>
          班级
        </template>
        <el-cascader
          style="width: 100%"
          v-if="isEdit"
          v-model="ClassSelect"
          :options="ClassOptions"
          filterable
          clearable
        ></el-cascader>
        <span v-if="!isEdit">{{
          DetailList.StartYear + DetailList.Major + DetailList.ClassNo + "班"
        }}</span>
        <i class="el-icon-edit" v-if="!isEdit"></i>
      </el-descriptions-item>
      <el-descriptions-item :span="ColSpan">
        <template slot="label">
          <i class="el-icon-location-information"></i>
          联系地址
        </template>
        <el-cascader
          style="width: 100%"
          v-if="isEdit"
          v-model="AddressSelect"
          :options="AddressOptions"
          filterable
          clearable
        ></el-cascader>
        <span v-if="!isEdit">{{
          DetailList.BuildingNo +
          "号" +
          DetailList.Building +
          "-" +
          DetailList.Floor +
          "楼-" +
          DetailList.RoomNo +
          "室"
        }}</span>
        <i class="el-icon-edit" v-if="!isEdit"></i>
      </el-descriptions-item>
      <el-descriptions-item :span="ColSpan">
        <template slot="label">
          <i class="el-icon-date"></i>
          注册时间
        </template>
        {{ DetailList.CreateTime }}
      </el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>

<script>
export default {
  name: "UserDetail",
  props: {
    GetUserDetailVisible: {
      type: Boolean,
    },
    ChangeUserDetailVisibleStatus: {
      type: Function,
    },
    DetailList: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 用户编辑状态
      isEdit: false,
      SubmitInfo: {
        UserAvatar: "",
        UserGender: null,
        UserAge: null,
        UserMPhoneNum: null,
        UserPhoneNum: null,
        Campus: "",
        Branch: "",
        BuildingNo: "",
        Building: "",
        Floor: null,
        RoomNo: null,
        StartYear: null,
        Major: "",
        ClassNo: null,
        Job: null,
        UserRole: null,
        File: null,
      },
      // 选中班级
      ClassSelect: [
        this.DetailList.StartYear,
        this.DetailList.Major,
        this.DetailList.ClassNo,
      ],
      // 选中地址
      AddressSelect: [
        this.DetailList.Building,
        this.DetailList.BuildingNo,
        this.DetailList.Floor,
        this.DetailList.RoomNo,
      ],
      RuleOptions: [
        {
          value: 0,
          label: "超级管理员",
        },
        {
          value: 1,
          label: "工程师",
        },
        {
          value: 2,
          label: "老师",
        },
        {
          value: 3,
          label: "学生",
        },
        {
          value: 4,
          label: "临时用户",
        },
      ],
      // 原来叫job
      UserTypeOptions: [
        {
          value: 0,
          label: "超级管理员",
        },
        {
          value: 1,
          label: "工程师",
        },
        {
          value: 2,
          label: "网管员(办)",
        },
        {
          value: 3,
          label: "网管员(宿)",
        },
        {
          value: 4,
          label: "普通用户",
        },
        {
          value: 5,
          label: "临时用户",
        },
      ],
      CampusOptions: [
        {
          value: "梅山校区",
          label: "梅山校区",
        },
        {
          value: "镜湖校区",
          label: "镜湖校区",
        },
      ],
      BranchOptionns: [
        {
          value: "设计与艺术分院",
          label: "设计与艺术分院",
        },
        {
          value: "机电分院",
          label: "机电分院",
        },
      ],
      MajorOptions: [
        {
          value: "计算机应用技术",
          label: "计算机应用技术",
        },
        {
          value: "机电自动化",
          label: "机电自动化",
        },
        {
          value: "护理专业",
          label: "护理专业",
        },
      ],
      ClassOptions: [
        {
          value: 20,
          label: "20级",
          children: [
            {
              value: "计算机应用技术",
              label: "计算机应用技术",
              children: [
                {
                  value: 1,
                  label: "1班",
                },
                {
                  value: 2,
                  label: "2班",
                },
                {
                  value: 3,
                  label: "3班",
                },
              ],
            },
          ],
        },
      ],
      GenderOptions: [
        {
          value: 0,
          label: "女",
        },
        {
          value: 1,
          label: "男",
        },
      ],
      // 函数自动生成
      AgeOptions: [],
      AddressOptions: [
        {
          value: "教学楼",
          label: "教学楼",
          children: [
            {
              value: 1,
              label: "1号教学楼",
              children: [
                {
                  value: 1,
                  label: "1楼",
                  children: [
                    {
                      value: 101,
                      label: "101室",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      HeadIcon:
        this.DetailList.UserAvatar ||
        "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
      Name: this.DetailList.UserName,
      RoleCode: this.DetailList.UserRole,
      RoleName: [
        "超管",
        "工程师",
        "网管员(办)",
        "网管员(宿)",
        "普通用户",
        "未知",
      ],
      // 用户详情显示
      UserDetailVisible: false,
      UserDetailTitle: "用户详情",
      // main窗口大小监测
      WindowSize: JSON.parse(localStorage.getItem("WindowsSize")),
    };
  },
  methods: {
    // 编辑信息
    Edit() {
      this.isEdit = true;
    },
    Submit() {
      console.log(this.SubmitInfo);
      this.isEdit = false;
    },
    // 获取main大小
    GetWindowSize() {
      this.$bus.$on("WindowSize", (data) => {
        this.WindowSize = data;
      });
    },
    autoAgeOptions() {
      for (let i = 0; i <= 100; i++) {
        this.AgeOptions.push({
          value: i,
          label: `${i}岁`,
        });
      }
    },

    beforeAvatarUpload() {},
    handleAvatarSuccess() {},
    ConversionJob(job) {
      switch (job) {
        case 0:
          return "超级管理员";
        case 1:
          return "工程师";
        case 2:
          return "网管员(办)";
        case 3:
          return "网管员(宿)";
        case 4:
          return "普通用户";
        default:
          return "未知";
      }
    },
    ConversionUserRole(role) {
      switch (role) {
        case 0:
          return "超管";
        case 1:
          return "工程师";
        case 2:
          return "老师";
        case 3:
          return "学生";
        default:
          return "未知";
      }
    },
  },
  computed: {
    // 头像列表
    AvatarList() {
      return [`${this.DetailList.UserAvatar}`];
    },
    // 弹窗宽度
    AlertWidth() {
      let width = this.WindowSize.width;
      if (width >= 1800) {
        return "40%";
      } else if (width > 1500 && width <= 1800) {
        return "50%";
      } else if (width > 1300 && width <= 1500) {
        return "60%";
      } else if (width > 700 && width <= 1300) {
        return "70%";
      } else {
        return "90%";
      }
    },
     // 弹窗换行（右侧移到到下面）
     ColSpan() {
      let width = this.WindowSize.width;
      return width <= 1150 ? 2 : 1;
    },
  },
  watch: {
    // 当检测到变化时执行父组件传来的函数
    // 间接实现改变父组件的属性
    UserDetailVisible(newvalue) {
      this.ChangeUserDetailVisibleStatus(newvalue);
    },
    GetUserDetailVisible(newvalue) {
      this.UserDetailVisible = newvalue;
    },
  },
  created() {
    this.GetWindowSize();
  },
  mounted() {
    // 自动生成age选项
    this.autoAgeOptions();
    this.GetWindowSize();
  },
  updated() {
    this.GetWindowSize();
  },
};
</script>

<style scoped lang="less">
@UploadSize: 100px;
.UserDetail {
  & .EditInfoBtn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  & .el-dialog__body {
    padding: 10px 20px;
  }
  & .UserAvatar {
    position: relative;
    padding: 0 !important;
    & > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  & .el-dialog__body {
    padding-top: 0;
  }

  & .Role {
    width: 80px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 12px;
    border-radius: 5px;
  }

  & .DetailImages > div {
    margin-left: 10px;
  }

  & .el-tab-pane {
    padding: 10px;
  }

  & .neirong {
    text-align: center !important;
  }
}

// 个人资料
::v-deep.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
::v-deep.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
::v-deep.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: @UploadSize;
  height: @UploadSize;
  line-height: @UploadSize;
  text-align: center;
}
.avatar {
  width: @UploadSize;
  height: @UploadSize;
  display: block;
}

::v-deep.dialogClass .el-dialog__body {
  padding: 0 20px 20px 20px;
  .el-descriptions__header {
    margin-bottom: 5px;
  }
}

.Tips {
  font-weight: bold;
  margin: 10px 0;
  & > span {
    padding: 5px;
    font-size: 12px;
    color: #797979;
  }
}
</style>