<template>
  <!-- 报修详情 -->
  <el-dialog
    :width="AlertWidth"
    top="10vh"
    custom-class="RepairDetail"
    :title="RepairDetailTitle"
    :visible.sync="RepairDetailVisible"
  >
    <!-- 左侧/上 -->
    <el-row>
      <el-col :span="ColSpan">
        <el-tabs :tab-position="LefttabPosition">
          <!-- 报修详情 -->
          <el-tab-pane label="报修详情">
            <el-descriptions
              contentClassName="neirong"
              :size="size"
              direction="vertical"
              :column="24"
              border
            >
              <el-descriptions-item label="报修时间" :span="8">
                {{
                  formatTime(DetailList.StartTime, `Y-M-D h:m:s`)
                }}</el-descriptions-item
              >
              <el-descriptions-item label="申请人" :span="6">{{
                DetailList.UserName
              }}</el-descriptions-item>
              <el-descriptions-item label="校区" :span="6">
                <el-tag type="success">{{
                  ExtractCampus(DetailList.Address)
                }}</el-tag>
              </el-descriptions-item>
              <el-descriptions-item label="角色" :span="4">
                <el-tag
                  effect="dark"
                  :color="UserRoleColor(DetailList.UserRole)"
                  type=""
                  >{{ DetailList.UserRole }}</el-tag
                >
              </el-descriptions-item>
              <el-descriptions-item label="手机/电话" :span="8">{{
                DetailList.UserMPhoneNum + " | " + DetailList.UserPhoneNum
              }}</el-descriptions-item>
              <el-descriptions-item label="报修地址" :span="16">{{
                DetailList.Address
              }}</el-descriptions-item>
              <el-descriptions-item label="详细地址" :span="24">{{
                DetailList.DetailAddress
              }}</el-descriptions-item>
            </el-descriptions>
            <el-divider>故障详情</el-divider>
            <el-descriptions
              contentClassName="neirong"
              :size="size"
              direction="vertical"
              :column="16"
              border
            >
              <el-descriptions-item label="故障设备" :span="8">{{
                DetailList.FaultDevice
              }}</el-descriptions-item>
              <el-descriptions-item label="设备状态" :span="8">{{
                DetailList.FaultState
              }}</el-descriptions-item>
              <el-descriptions-item label="故障描述" :span="16">{{
                DetailList.FaultDetail
              }}</el-descriptions-item>
              <el-descriptions-item
                label="图片描述"
                :span="10"
                v-if="DetailList.Images && DetailList.Images.length != 0"
              >
                <div class="DetailImages">
                  <el-image
                    v-for="item in DetailList.Images"
                    :key="item.id"
                    style="width: 50px; height: 50px"
                    :src="item"
                    :preview-src-list="DetailList.Images"
                  >
                  </el-image>
                </div>
              </el-descriptions-item>
              <el-descriptions-item
                label="视频描述"
                :span="6"
                v-if="DetailList.Videos && DetailList.Videos.length != 0"
              >
                <div class="DetailImages">
                  <el-image
                    v-for="(item, index) in DetailList.Videos"
                    :key="index"
                    style="width: 50px; height: 50px"
                    :src="item.CoverUrl"
                    @click="
                      OpenVideoPlayer(
                        item.VideoUrl,
                        item.CoverUrl,
                        DetailList.FaultID
                      )
                    "
                  >
                  </el-image>
                </div>
                <el-dialog
                  :title="CurrentTitle"
                  :show-close="true"
                  custom-class="VideoView"
                  :destroy-on-close="true"
                  :width="VideoWidth"
                  :visible.sync="innerVisible"
                  append-to-body
                >
                  <video
                    ref="player"
                    playsinline
                    controls
                    :src="CurrentVideoUrl"
                    :data-poster="CurrentImgUrl"
                    width="100%"
                    height="100%"
                  ></video>
                </el-dialog>
              </el-descriptions-item>
            </el-descriptions>
          </el-tab-pane>
          <!-- 订单进度 -->
          <el-tab-pane label="订单进度">
            <el-timeline :reverse="false">
              <el-timeline-item
                v-for="item in DetailList.Activities"
                :key="item.id"
                :icon="ActivityIcon[item.Stauts]"
                :type="ActivityType[item.Stauts]"
                :color="ActivityColor[item.Stauts]"
                :size="ActivitySize"
                :timestamp="formatTime(item.TimeStamp, 'Y-M-D h: m: s')"
              >
                {{ item.Content }}
              </el-timeline-item>
            </el-timeline>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <!-- 右侧/下 -->
      <el-col :span="ColSpan">
        <el-tabs :tab-position="RighttabPosition">
          <!-- 维修详情 -->
          <el-tab-pane label="维修详情">
            <el-descriptions
              contentClassName="neirong"
              :size="size"
              direction="vertical"
              :column="4"
              border
            >
              <el-descriptions-item label="受理时间">{{
                formatTime(DetailList.AcceptanceTime, "Y-M-D h:m:s")
              }}</el-descriptions-item>
              <el-descriptions-item label="处理人员">{{
                DetailList.AcceptingPersonName
              }}</el-descriptions-item>
              <el-descriptions-item label="职位">
                <el-tag>{{ JobName[DetailList.AcceptingJob] }}</el-tag>
              </el-descriptions-item>
              <el-descriptions-item label="手机号码">{{
                DetailList.AcceptingPersonMPNum
              }}</el-descriptions-item>
              <el-descriptions-item label="服务方">
                <el-tag type="success" color="">校-网络中心</el-tag>
              </el-descriptions-item>
              <el-descriptions-item label="办公电话">{{
                DetailList.AcceptingPersonPNum
              }}</el-descriptions-item>
              <el-descriptions-item label="办公地址"
                >梅山校区-图书馆-508</el-descriptions-item
              >
            </el-descriptions>
            <el-divider>线上交流</el-divider>
          </el-tab-pane>

          <!-- 订单评价 -->
          <el-tab-pane label="订单评价">订单评价</el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: "RepairDetail",
  props: {
    GetRepairDetailVisible: {
      type: Boolean,
    },
    ChangeRepairDetailVisibleStatus: {
      type: Function,
    },
    DetailList: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 报修详情显示
      RepairDetailVisible: false,

      // 职位名称
      JobName: [
        "超管",
        "工程师",
        "网管员(办)",
        "网管员(宿)",
        "普通用户",
        "未知",
      ],
      // 选项卡位置
      LefttabPosition: "top",
      RighttabPosition: "top",
      size: "small",

      // 视频窗口显示
      // player: null,   //视频实例
      innerVisible: false,
      CurrentTitle: "", //当前标题
      CurrentImgUrl: "", //当前图片链接
      CurrentVideoUrl: "", //当前视频链接

      // 订单进度
      ActivityIcon: [
        "el-icon-coin",
        "el-icon-loading",
        "el-icon-success",
        "el-icon-alarm-clock",
        "el-icon-sort",
      ],
      ActivityType: ["danger", "warning", "success", "primary", "info"],
      ActivityColor: ["#F56C6C", "#E6A23C", "#67C23A", "#409EFF", "#909399"],
      ActivitySize: "large",
      // 进度状态
      ActiveCode: [
        "用户:-报修人-创建报修单成功! ",
        "已接单,受理人:-受理人-",
        "-受理人-正在赶来途中... ...",
        "-受理人-已到达,正在维护中... ...",
        "报修订单维护已完成 ",
        "经-调整人-调整,维护人员由-受理人-转为-接头人-",
      ],

      RepairDetailTitle: "报修详情",
      WindowSize: JSON.parse(localStorage.getItem("WindowsSize")),
    };
  },
  methods: {
    // 获取main大小
    GetWindowSize() {
      this.$bus.$on("WindowSize", (data) => {
        this.WindowSize = data;
      });
    },
    // 播放视频
    OpenVideoPlayer(VideoUrl, ImgUrl, title) {
      // console.log("传入的地址");
      // console.log(VideoUrl);
      // console.log(ImgUrl);
      // console.log("------------------截止------------------");
      // console.log("赋值前的地址")
      // console.log(this.CurrentVideoUrl);
      // console.log(this.CurrentImgUrl);
      // console.log("------------------截止------------------");
      // console.log(title);
      this.CurrentTitle = "视频详情:" + title;
      this.CurrentVideoUrl = VideoUrl;
      this.CurrentImgUrl = ImgUrl;
      // console.log("赋值后的地址")
      // console.log(this.CurrentVideoUrl);
      // console.log(this.CurrentImgUrl);
      // console.log("------------------截止------------------");
      this.innerVisible = true;
    },
    UserRoleColor(role) {
      return role === "学生"
        ? "#2196F3"
        : role === "老师"
        ? "#4CAF50"
        : "#909399";
    },

    // 提取地址“-”前的信息（这里是校区）
    ExtractCampus(address) {
      if (address) {
        let AddressArray = address.split("-");
        return AddressArray[0];
      }
      return "未知";
    },
    //数据转化时间戳转换用
    formatNumber(n) {
      n = n.toString();
      return n[1] ? n : "0" + n;
    },
    // 时间戳转换
    formatTime(number, format) {
      //转换日期
      var formateArr = ["Y", "M", "D", "h", "m", "s"];
      var returnArr = [];
      var date = new Date(number * 1000);
      returnArr.push(date.getFullYear());
      returnArr.push(this.formatNumber(date.getMonth() + 1));
      returnArr.push(this.formatNumber(date.getDate()));
      returnArr.push(this.formatNumber(date.getHours()));
      returnArr.push(this.formatNumber(date.getMinutes()));
      returnArr.push(this.formatNumber(date.getSeconds()));
      for (var i in returnArr) {
        format = format.replace(formateArr[i], returnArr[i]);
      }
      return format;
    },
  },
  computed: {
    // 弹窗宽度
    AlertWidth() {
      let width = this.WindowSize.width;
      if (width >= 1800) {
        return "50%";
      } else if (width > 1500 && width <= 1800) {
        return "60%";
      } else if (width > 1300 && width <= 1500) {
        return "70%";
      } else if (width > 700 && width <= 1300) {
        return "80%";
      } else {
        return "100%";
      }
    },
    // 视频播放窗口
    VideoWidth() {
      let width = this.WindowSize.width;
      if (width <= 1000) {
        return "85%";
      } else if (width <= 1100 && width > 1050) {
        return "47%";
      } else if (width <= 1150 && width > 1100) {
        return "45%";
      } else if (width <= 1200 && width > 1150) {
        return "43%";
      } else if (width <= 1250 && width > 1200) {
        return "41%";
      } else if (width <= 1300 && width > 1250) {
        return "39%";
      } else if (width <= 1350 && width > 1300) {
        return "37%";
      } else if (width <= 1400 && width > 1350) {
        return "35%";
      } else if (width <= 1450 && width > 1400) {
        return "33%";
      } else if (width <= 1500 && width > 1450) {
        return "31%";
      } else if (width <= 1550 && width > 1500) {
        return "29%";
      } else if (width <= 1600 && width > 1550) {
        return "27%";
      } else {
        return "25%";
      }
    },
    // 弹窗换行（右侧移到到下面）
    ColSpan() {
      let width = this.WindowSize.width;
      return width <= 1150 ? 24 : 12;
    },
  },
  watch: {
    // 当检测到变化时执行父组件传来的函数
    // 间接实现改变父组件的属性
    RepairDetailVisible(newvalue) {
      this.ChangeRepairDetailVisibleStatus(newvalue);
    },
    GetRepairDetailVisible(newvalue) {
      this.RepairDetailVisible = newvalue;
    },
  },
  created() {
    this.GetWindowSize();
  },
  mounted() {
    this.GetWindowSize();
  },
  updated() {
    this.GetWindowSize();
  },
};
</script>

<style lang="less">
.RepairDetail {
  & .el-dialog__body {
    padding-top: 0;
  }

  & .Role {
    width: 80px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 12px;
    border-radius: 5px;
  }

  & .DetailImages > div {
    margin-left: 10px;
  }

  & .el-tab-pane {
    padding: 10px;
  }

  & .neirong {
    text-align: center !important;
  }
}

.VideoView {
  max-width: 100%;
  margin: 0 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & > .el-dialog__header {
    box-sizing: content-box;
    // padding: 0;
    // display: none; //隐藏视频框的header
  }

  & > .el-dialog__body {
    padding: 0px 3px 0 3px;
  }
}

.RepairDetail {
  & .el-dialog__body {
    padding-top: 0;
  }

  & .Role {
    width: 80px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 12px;
    border-radius: 5px;
  }

  & .DetailImages > div {
    margin-left: 10px;
  }

  & .el-tab-pane {
    padding: 10px;
  }

  & .neirong {
    text-align: center !important;
  }
}

.VideoView {
  max-width: 100%;
  margin: 0 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & > .el-dialog__header {
    box-sizing: content-box;
    // padding: 0;
    // display: none; //隐藏视频框的header
  }

  & > .el-dialog__body {
    padding: 0px 3px 0 3px;
  }
}
</style>