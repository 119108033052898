<template>
  <div class="kq">
    <el-form ref="form" :model="form" label-width="150px">
      <el-form-item label="是否启用考勤">
        <el-checkbox label="启用考勤" name="type"></el-checkbox>
      </el-form-item>
      <el-form-item label="打卡区域">
        <el-select v-model="form.region" placeholder="请选择打卡区域">
          <el-option label="办公室" value="shanghai"></el-option>
          <el-option label="宿舍" value="beijing"></el-option>
          <el-option label="" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="打卡时间">
        <el-col :span="6">
          <el-time-picker
            placeholder="上班时间"
            v-model="form.date2"
            style="width: 100%"
          ></el-time-picker>
        </el-col>
        <el-col>
          <span>-</span>
        </el-col>
        <el-col :span="6">
          <el-time-picker
            placeholder="下班时间"
            v-model="form.date2"
            style="width: 100%"
          ></el-time-picker>
        </el-col>
      </el-form-item>
      <el-form-item label="工作日">
        <el-checkbox-group v-model="form.type">
          <el-checkbox label="周一" name="type"></el-checkbox>
          <el-checkbox label="周二" name="type"></el-checkbox>
          <el-checkbox label="周三" name="type"></el-checkbox>
          <el-checkbox label="周四" name="type"></el-checkbox>
          <el-checkbox label="周五" name="type"></el-checkbox>
          <el-checkbox label="周六" name="type"></el-checkbox>
          <el-checkbox label="周日" name="type"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="公告">
        <el-input type="textarea"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存修改</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "AttendanceSettings",
  data() {
    return {
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
  },
};
</script>

<style lang="less">
.kq {
  width: 100%;
  height: 100%;
  background-color: #323232;
  border-radius: 5px;
  padding: 20px 15px;
  & * {
    color: #ccc;
  }
}
</style>