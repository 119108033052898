<template>
    <div>
        系统设置-工单设置
    </div>
</template>

<script>
export default {
    name: 'WorkOrderSettings',
    components: {}
}
</script>
 
<style scoped></style>