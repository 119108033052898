//封装节流方法
let throttle = function (callback, wait) {
    let throttle = false;
    return () => {
        if (!throttle) {
            throttle = true
            setTimeout(function () {
                callback();
                throttle = false;
            }, wait)
        }
    }
}

// 封装防抖函数
let debounce = function (callback, wait) {
    let timeout;
    return () => {
        clearTimeout(timeout)
        timeout = setTimeout(callback, wait);
    }
}
// 分别暴露
export { throttle, debounce }
