<template>
  <el-row class="RepairList">
    <!-- 表单控制区 -->
    <el-row class="FormControlTop" type="flex" justify="space-between">
      <el-col :span="6">
        <slot name="SearchInput"></slot>
      </el-col>
      <el-col :span="4" class="buttons">
        <el-button v-if="$route.name === 'AllUsers'" size="small" type="primary"
          >添加用户</el-button
        >
        <el-button
          size="small"
          :disabled="DelSelectBtn"
          type="danger"
          @click="DeleteSelect"
          >批量删除</el-button
        >
      </el-col>
    </el-row>
    <!-- 表单区 -->
    <el-table
      v-loading="isLoading"
      :data="GetUserInfo"
      @selection-change="handleSelectionChange"
      ref="table"
      class="FormInfomation"
      border
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      highlight-selection-row
      :max-height="WindowSize.height - 236"
    >
      <!-- 选择框 -->
      <el-table-column fixed type="selection" width="55"></el-table-column>
      <el-table-column prop="UserID" label="学号/工号" width="120">
      </el-table-column>
      <el-table-column prop="UserName" label="姓名" width="120">
      </el-table-column>
      <el-table-column prop="UserGender" label="性别" width="80">
        <template slot-scope="UserGender">
          <el-tag
            effect="dark"
            :color="UserGender.row.UserGender === 0 ? '#FF4081' : '#2196F3'"
          >
            {{ UserGender.row.UserGender === 0 ? "女" : "男" }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="UserRole" label="角色" width="120">
        <template slot-scope="UserRole">
          <el-tag
            effect="dark"
            :color="ConversionUserRoleColor(UserRole.row.UserRole)"
            >{{ ConversionUserRole(UserRole.row.UserRole) }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="Job" label="用户类型" width="150">
        <template slot-scope="Job">
          <el-tag effect="dark" :color="ConversionJobColor(Job.row.Job)">{{
            ConversionJob(Job.row.Job)
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="UserMPhoneNum"
        label="手机"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="UserPhoneNum"
        label="电话"
        width="120"
      ></el-table-column>
      <el-table-column prop="Campus" label="校区" width="120"></el-table-column>
      <el-table-column prop="Branch" label="分院" width="150"></el-table-column>
      <el-table-column prop="Major" label="班级" width="180">
        <template slot-scope="Major">
          {{ Major.row.StartYear + Major.row.Major + Major.row.ClassNo + "班" }}
        </template>
      </el-table-column>
      <el-table-column prop="Building" label="地址" width="220">
        <template slot-scope="Building">
          {{
            Building.row.BuildingNo +
            "号" +
            Building.row.Building +
            "-" +
            Building.row.Floor +
            "楼-" +
            Building.row.RoomNo +
            "室"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="CreateTime" label="注册时间" width="200">
        <template slot-scope="CreateTime">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px; font-weight: bold">
            {{ CreateTime.row.CreateTime }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="id"
        label="操作"
        width="150"
        min-width="150"
        fixed="right"
      >
        <template slot-scope="id">
          <el-button size="mini" type="pramary" @click="ViewDetails(id.row)"
            >查看</el-button
          >
          <el-popconfirm
            :title="DelTipTitle(id.row)"
            @confirm="Confirm(id.row)"
            @cancel="Cancel(id.row)"
          >
            <el-button slot="reference" size="mini" type="danger"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <slot name="FormControlBottom"></slot>
    <!-- 用户详情 -->
    <UserDetail
      :DetailList="DetailList"
      :ChangeUserDetailVisibleStatus="ChangeUserDetailVisibleStatus"
      :GetUserDetailVisible="UserDetailVisible"
    ></UserDetail>
  </el-row>
</template>

<script>
import UserDetail from "./UserDetail";
export default {
  name: "UserFormList",
  components: { UserDetail },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    GetUserInfo: {
      type: Array,
      default: () => [],
    },
    getuserformlist: {
      type: Function,
    },
  },
  data() {
    return {
      // 报修详情显示
      UserDetailVisible: false,
      DetailList: {},

      // window窗口大小监测
      WindowSize: { width: 0, height: 0 },

      // 批量删除按钮禁用状态(默认禁用)
      DelSelectBtn: true,
    };
  },
  methods: {
    // 查看按钮
    ViewDetails(row) {
      this.UserDetailVisible = true;
      this.UserDetailTitle = `报修单号：${row.UserID}`;
      this.DetailList = row;
      console.log(this.DetailList);
    },
    // 报修详情显示状态转换
    ChangeUserDetailVisibleStatus(state) {
      this.UserDetailVisible = state;
    },
    // 获取main大小
    GetWindowSize() {
      this.$bus.$on("WindowSize", (data) => {
        console.log();
        this.WindowSize = data;
      });
    },

    // 多选框选择更改时
    handleSelectionChange(selection) {
      // selection包含该行所有信息
      if (selection.length === 0) {
        this.DelSelectBtn = true;
      } else {
        this.DelSelectBtn = false;
      }
    },

    // 删除选中
    DeleteSelect() {
      // 加删除弹窗
      let selections = this.$refs.table.selection;
      if (selections.length != 0) {
        this.$confirm("此操作将删除选中所有选中用户, 是否继续?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "danger",
        })
          .then(() => {
            let deletedCount = 0;
            selections.forEach((item) => {
              this.axios
                .delete(`http://112.124.26.92:3001/UserList/${item.id}`)
                .then(() => {
                  deletedCount++;
                  if (deletedCount === selections.length) {
                    this.getuserformlist();
                    this.$message({
                      type: "success",
                      message: `成功删除了${selections.length}个用户`,
                    });
                  }
                })
                .catch((err) => {
                  console.error(err);
                  this.$message({
                    type: "danger",
                    message: err,
                  });
                });
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },

    // 删除单个用户
    // 确认删除
    Confirm(row) {
      this.axios
        .delete(`http://112.124.26.92:3001/UserList/${row.id}`)
        .then(() => {
          this.getuserformlist();
          this.$message({
            type: "success",
            message: `已成功删除“${row.UserName}”，账号为“${row.UserID}”`,
          });
        })
        .catch((err) => {
          console.error(err);
          this.$message({
            type: "danger",
            message: err,
          });
        });
    },

    // 删除提示内容
    DelTipTitle(row) {
      return `确认删除名字为“${row.UserName}”账号为“${row.UserID}”的用户吗？`;
    },
    // 取消删除
    Cancel(row) {
      this.$message({
        type: "info",
        message: `已取消删除“${row.UserName}”用户`,
      });
    },

    // 获取当前时间戳
    NowTimeStamp() {
      console.log("获取了一次时间戳");
      return parseInt(new Date().getTime() / 1000);
    },
    // 角色标签颜色
    ConversionUserRoleColor(role) {
      switch (role) {
        case 0:
          return "#fd5d5d";
        case 1:
          return "#E6A23C";
        case 2:
          return "#4CAF50";
        case 3:
          return "#2196F3";
        default:
          return "#909399";
      }
    },
    ConversionJobColor(job) {
      switch (job) {
        case 0:
          return "#fd5d5d";
        case 1:
          return "#E6A23C";
        case 2:
          return "#63d563";
        case 3:
          return "#63d563";
        case 4:
          return "#6558f4";
        default:
          return "#909399";
      }
    },
    ConversionJob(job) {
      switch (job) {
        case 0:
          return "超级管理员";
        case 1:
          return "工程师";
        case 2:
          return "网管员(办)";
        case 3:
          return "网管员(宿)";
        case 4:
          return "普通用户";
        default:
          return "未知";
      }
    },
    ConversionUserRole(role) {
      switch (role) {
        case 0:
          return "超管";
        case 1:
          return "工程师";
        case 2:
          return "老师";
        case 3:
          return "学生";
        default:
          return "未知";
      }
    },
  },
  created() {
    this.GetWindowSize();
  },
  mounted() {
    this.GetWindowSize();
  },
  updated() {
    this.GetWindowSize();
  },
};
</script>

<style lang="less">
@BGC-day-dark: #262626;
@BGC-day-Light: #323232;

// 表单操作区
.FormControlTop {
  height: 50px;
  padding: 0 10px 0 10px;
  border-radius: 5px 5px 0 0;
  // 下面这行删除
  background-color: @BGC-day-Light;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & .el-select .el-input {
    margin-left: 5px;
    width: 110px;
  }

  & .input-with-select > .el-input__inner {
    min-width: 220px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

// 表单区
.FormInfomation {
  width: 100%;

  & .no-padding {
    padding: 0 !important;
  }

  & .cell {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
  }
}
</style>