<template>
    <div>
        系统设置-模板设置
    </div>
</template>

<script>
export default {
    name: 'TemplateSettings',
    components: {}
}
</script>
 
<style scoped></style>