<template>
    <div>
        统计报表-管理员工作量统计
    </div>
</template>

<script>
export default {
    name: 'WorkloadStatistics',
    components: {}
}
</script>
 
<style scoped></style>