<template>
  <!-- 人员管理-所有用户 -->
  <UserFormList
    :isLoading="isLoading"
    :GetUserInfo="UserInfo"
    :getuserformlist="GetUserInfo"
  >
    <!-- 搜索框 -->
    <el-input
      @change="SearchKey"
      :clearable="true"
      slot="SearchInput"
      size="medium"
      :placeholder="placeholder"
      v-model="keywords"
      class="input-with-select"
    >
      <el-select v-model="keytype" slot="prepend" placeholder="查询字段">
        <el-option label="姓名" value="1"></el-option>
        <el-option label="学号/工号" value="2"></el-option>
        <el-option label="手机号码" value="3"></el-option>
        <el-option label="电话" value="4"></el-option>
      </el-select>
      <el-button
        slot="append"
        icon="el-icon-search"
        @click="SearchKey"
      ></el-button>
    </el-input>
    <el-row class="FormControlBottom" slot="FormControlBottom">
      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pagesize"
        layout=" total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-row>
  </UserFormList>
</template>

<script>
import UserFormList from "../UserFormList";
export default {
  name: "AllUsers",
  components: { UserFormList },
  data() {
    return {
      // 筛选框
      keywords: "",
      keytype: "",

      // 分页器
      total: 0,
      pagesize: 11,
      currentPage: 1,

      isLoading: false,
      UserInfo: [],
    };
  },
  computed: {
    placeholder() {
      switch (this.keytype) {
        case "1":
          return "请输入完整姓名";
        case "2":
          return "请输入完整学号/工号";
        case "3":
          return "请输入完整手机号码";
        case "4":
          return "请输入完整电话号码";
        default:
          return "模糊查询";
      }
    },
  },
  methods: {
    // 获取用户信息
    GetUserInfo() {
      this.isLoading = true;
      this.GetUsercount();
      this.axios
        .get(`http://112.124.26.92:3001/UserList?_page=${this.currentPage}&_limit=${this.pagesize}`)
        .then((response) => {
          this.UserInfo = response.data;
          console.log("请求用户列表");
          this.isLoading = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取报修单总数量
    GetUsercount() {
      /* 这是开启代理服务器后的，如不跨源则改为请求地址：
            并关闭代理服务器 */
      console.log("请求了用户数量");
      this.axios
        .get(`http://112.124.26.92:3001/UserList/count`)
        .then((response) => {
          this.total = response.data.count;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleCurrentChange(currentpage) {
      // 当前页码发生变化
      this.currentPage = currentpage;
      this.GetUserInfo();
    },
    // 搜索功能
    SearchKey() {
      if (this.keywords === "") {
        this.GetUserInfo();
      } else {
        this.isLoading = true;
        switch (this.keytype) {
          case "1": //查询用户名
            this.axios
              .get(`http://112.124.26.92:3001/UserList?UserName=${this.keywords}`)
              .then((response) => {
                this.UserInfo = response.data;
                this.isLoading = false;
              });
            break;
          case "2": //查询学号/工号
            this.axios
              .get(`http://112.124.26.92:3001/UserList?UserID=${this.keywords}`)
              .then((response) => {
                this.UserInfo = response.data;
                this.isLoading = false;
              });
            break;
          case "3": //查询手机号码
            this.axios
              .get(`http://112.124.26.92:3001/UserList?UserMPhoneNum=${this.keywords}`)
              .then((response) => {
                this.UserInfo = response.data;
                this.isLoading = false;
              });
            break;
          case "4": //查询电话号码
            this.axios
              .get(`http://112.124.26.92:3001/UserList?UserPhoneNum=${this.keywords}`)
              .then((response) => {
                this.UserInfo = response.data;
                this.isLoading = false;
              });
            break;
          default: //模糊查询
            this.axios
              .get(`http://112.124.26.92:3001/UserList?q=${this.keywords}`)
              .then((response) => {
                this.UserInfo = response.data;
                this.isLoading = false;
              });
            break;
        }
      }
    },
  },
  mounted() {
    this.GetUserInfo();
  },
};
</script>

<style scoped lang="less">
@BGC-day-dark: #262626;
@BGC-day-Light: #323232;

.FormControlBottom {
  height: 50px;
  padding: 0 10px 0 10px;
  border-radius: 0 0 5px 5px;
  // 下面这行删除
  background-color: @BGC-day-Light;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>