<template>
  <!-- 系统设置-模块设置 -->
  <div class="table">
    <el-table :data="tableData" border style="width: 700px">
      <el-table-column
        prop="Menu"
        label="一级菜单"
        width="180"
        :span-method="handleSpanMethod"
      >
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row._Visible">{{
            scope.row._title
          }}</el-checkbox>
        </template>
      </el-table-column>
      <el-table-column prop="MenuChild" label="二级菜单" width="500">
        <template slot-scope="scope">
          <el-checkbox
            v-for="item in scope.row._MenuChilds"
            :key="item._id"
            v-model="item._isShow"
            >{{ item._SubTag }}</el-checkbox
          >
        </template>
      </el-table-column>
    </el-table>
    <el-button type="primary">保存修改</el-button>
  </div>
</template>

<script>
export default {
  name: "ModuleSettings",
  components: {},
  data() {
    return {
      tableData: JSON.parse(localStorage.getItem("MenuList")),
    };
  },
  methods: {
    handleSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0 && rowIndex === 0) {
        return {
          rowspan: 4,
          colspan: 1,
        };
      }
    },
  },
};
</script>
 
<style scoped lang="less">
.table {
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: #323232;
  border-radius: 5px;
}
</style>