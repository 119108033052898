<template>
  <!-- 添加报修 -->
  <el-dialog
    :width="AlertWidth"
    top="15vh"
    title="添加报修单"
    :visible.sync="RepairFormVisible"
  >
    <el-form ref="form" :model="AddRepairInfo" label-width="80px">
      <el-row :gutter="50">
        <el-col :span="ColSpan">
          <el-row>
            <el-col :span="12">
              <el-form-item label="申请人" prop="name">
                {{ AddRepairInfo.UserName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="学号/工号" prop="UserID">
                {{ AddRepairInfo.UserID }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="手机号码" prop="UserMPhoneNum">
            <el-input v-model="AddRepairInfo.UserMPhoneNum"></el-input>
          </el-form-item>
          <el-form-item label="办公电话" prop="UserPhoneNum">
            <el-input v-model="AddRepairInfo.UserPhoneNum"></el-input>
          </el-form-item>
          <el-form-item label="联系地址" prop="Address">
            <el-cascader
              :clearable="true"
              v-model="AddRepairInfo.Address"
              style="width: 100%"
              separator=" | "
              :options="AddressOptions"
            >
              <template slot-scope="{ node, data }">
                <span>{{ data.label }}</span>
                <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
              </template>
            </el-cascader>
            <el-checkbox v-model="IsIncreaseAddress"
              >地址补充(当上方地址不足以描述具体位置)</el-checkbox
            >
          </el-form-item>
          <el-form-item
            v-if="IsIncreaseAddress"
            label="详细地址"
            prop="DetailAddress"
          >
            <el-input
              v-model="AddRepairInfo.DetailAddress"
              type="textarea"
              :rows="2"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="故障设备" prop="FaultDevice">
            <el-cascader
              :clearable="true"
              v-model="SelectDevice"
              style="width: 100%"
              separator=" | "
              :options="FaultOptions"
            >
              <template slot-scope="{ node, data }">
                <span>{{ data.label }}</span>
                <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
              </template>
            </el-cascader>
          </el-form-item>
          <el-form-item label="故障描述" prop="FaultDetail">
            <el-input
              v-model="AddRepairInfo.FaultDetail"
              type="textarea"
              :rows="2"
              placeholder="请输入故障描述"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="ColSpan">
          <el-row>
            <el-col>
              <el-form-item label="上传图片">
                <el-upload
                  :class="{ Upload: true, hide: HideImgUpload }"
                  :limit="AllowImgNum"
                  :auto-upload="false"
                  :accept="ImgAcceptType"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  list-type="picture-card"
                  :on-success="ImgUploadSuccess"
                  :on-error="ImgUploadError"
                  :on-progress="ImgUploading"
                  :on-change="ImgUploadChange"
                  :before-upload="ImgBeforeUpload"
                  :before-remove="ImgBeforeRemove"
                  :on-preview="ImgPreview"
                  :on-exceed="ImgOutOfPreset"
                  :on-remove="ImgRemove"
                  :file-list="ImgPreviewList"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog
                  :destroy-on-close="true"
                  custom-class="Preview"
                  append-to-body
                  :visible.sync="ImgPreviewVisible"
                >
                  <img :src="ImgPreviewUrl" alt="" />
                </el-dialog>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="上传视频">
                <el-upload
                  :class="{ Upload: true, hide: HideVideoUpload }"
                  :limit="AllowVideoNum"
                  :auto-upload="false"
                  :accept="VideoAcceptType"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  list-type="picture-card"
                  :on-success="VideoUploadSuccess"
                  :on-error="VideoUploadError"
                  :on-progress="VideoUploading"
                  :on-change="VideoUploadChange"
                  :before-upload="VideoBeforeUpload"
                  :before-remove="VideoBeforeRemove"
                  :on-preview="VideoPreview"
                  :on-exceed="VideoOutOfPreset"
                  :on-remove="VideoRemove"
                  :file-list="VideoPreviewList"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog
                  title="视频预览"
                  :show-close="true"
                  custom-class="VideoView"
                  :destroy-on-close="true"
                  :width="VideoWidth"
                  :visible.sync="VideoPreviewVisible"
                  append-to-body
                >
                  <video
                    ref="player"
                    playsinline
                    controls
                    :src="VideoPreviewUrl"
                    :data-poster="VideoCoverUrl"
                    width="100%"
                  ></video>
                </el-dialog>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-checkbox v-model="IsSaveAddress">保存地址</el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit()">发起报修</el-button>
            <el-button @click="RepairFormVisible = false">取消</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: "AddRepairForm",
  props: {
    GetRepairFormVisible: {
      type: Boolean,
    },
    ChangeRepairFormVisibleStatus: {
      type: Function,
    },
    AddressOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      FaultOptions: [
        {
          value: "电脑",
          label: "电脑",
          children: [
            {
              value: "软件问题",
              label: "软件问题",
            },
            {
              value: "重装系统",
              label: "重装系统",
            },
            {
              value: "不开机",
              label: "不开机",
            },
            {
              value: "蓝屏",
              label: "蓝屏",
            },
            {
              value: "其它",
              label: "其它",
            },
          ],
        },
        {
          value: "打印机",
          label: "打印机",
          children: [
            {
              value: "打印机调试",
              label: "打印机调试",
            },
            {
              value: "其它",
              label: "其它",
            },
          ],
        },
      ],
      RepairFormVisible: false,
      AddRepairInfo: {
        FaultID: this.nanoid(),
        UserID: this.$store.state.UserInfo.UserID || "",
        UserName: this.$store.state.UserInfo.UserName || "",
        UserRole: this.$store.state.UserInfo.UserRole || null,
        UserPhoneNum: this.$store.state.UserInfo.UserPhoneNum || null,
        UserMPhoneNum: this.$store.state.UserInfo.UserMPhoneNum || null,
        Address: "",
        DetailAddress: "",
        FaultDevice: "",
        FaultState: "",
        FaultDetail: "",
        Images: "",
        Videos: "",
      },

      SelectDevice: [],
      // 地址补充
      IsIncreaseAddress: false,
      // 是否保存地址
      IsSaveAddress: false,

      // 上传文件
      // 接受上传的图片类型，逗号分隔
      ImgAcceptType: ".jpg,.png",
      // 图片预览地址
      ImgPreviewUrl: "",
      // 图片预览列表
      ImgPreviewList: [],
      // 允许上传图片数量
      AllowImgNum: 3,
      // 超出上传数量限制后隐藏上传按钮
      HideImgUpload: false,
      // 图片预览弹窗显示状态
      ImgPreviewVisible: false,
      // 接受上传的视频类型，逗号分隔
      VideoAcceptType: ".mp4",
      // 视频预览地址
      VideoPreviewUrl: "",
      // 视频预览列表
      VideoPreviewList: [],
      // 允许上传视频数量
      AllowVideoNum: 1,
      // 超出上传数量限制后隐藏上传按钮
      HideVideoUpload: false,
      // 视频封面地址
      VideoCoverUrl: "",
      // 视频封面默认
      videoDefCover: "",
      // 视频预览弹窗显示状态
      VideoPreviewVisible: false,

      // main窗口大小监测
      WindowSize: JSON.parse(localStorage.getItem("WindowsSize")),
    };
  },
  methods: {
    // 获取main大小
    GetWindowSize() {
      this.$bus.$on("WindowSize", (data) => {
        this.WindowSize = data;
      });
    },
    // 提交报修单
    onSubmit() {
      console.log(this.AddRepairInfo);
    },
    // #region 添加报修单上传功能相关方法
    // 图片预览按钮被点击
    ImgPreview(file) {
      this.ImgPreviewVisible = true;
      this.ImgPreviewUrl = file.url;
      console.log("图片预览文件", file);
    },
    // 删除图片按钮被点击
    ImgRemove(file, fileList) {
      console.log("删除文件：", file);
      console.log("删除文件后的文件列表：", fileList);
    },
    // 图片上传成功
    // 参数 response, file, fileList
    ImgUploadSuccess() {
      console.log("图片上传成功");
    },
    // 图片上传失败
    // 参数 err, file, fileList
    ImgUploadError() {
      console.log("图片上传失败");
    },
    // 图片上传时
    // 参数 event, file, fileList
    ImgUploading() {
      console.log("图片上传时");
    },
    // 图片文件状态改变，添加或删除图片
    // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
    // 参数 file, fileList
    ImgUploadChange(file, fileList) {
      this.HideImgUpload = fileList.length >= this.AllowImgNum;
      console.log("图片文件状态改变", file);
    },
    // 图片上传前
    // 参数 file
    ImgBeforeUpload() {
      console.log("图片上传前");
    },
    // 图片移除前
    // 参数 file, fileList
    ImgBeforeRemove(file, fileList) {
      console.log("将要删除的图片文件：", file);
      console.log("删除前图片文件列表：", fileList);
    },

    // 视频上传成功
    // 参数 response, file, fileList
    VideoUploadSuccess() {
      console.log("视频上传成功");
    },
    // 视频上传失败
    // 参数 err, file, fileList
    VideoUploadError() {
      console.log("视频上传失败");
    },
    // 视频上传时
    // 参数 event, file, fileList
    VideoUploading() {
      console.log("视频上传时");
    },
    // 视频文件状态改变，添加或删除视频
    // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
    // 参数 file, fileList
    VideoUploadChange(file, fileList) {
      // 当文件上传超出数量时隐藏上传图标
      this.HideVideoUpload = fileList.length >= this.AllowVideoNum;
      console.log("视频文件状态改变", file);
    },
    // 视频上传前
    // 参数 file
    VideoBeforeUpload(file) {
      console.log("视频上传前", file);
    },
    // 视频移除前
    // 参数 file, fileList
    VideoBeforeRemove(file, fileList) {
      console.log("将要删除的视频文件：", file);
      console.log("删除视频前文件列表：", fileList);
    },
    // 视频预览按钮被点击
    VideoPreview(file) {
      this.VideoPreviewVisible = true;
      this.VideoPreviewUrl = file.url;
      console.log("点击了视频预览按钮", file);
    },
    // 删除视频按钮被点击
    VideoRemove(file, fileList) {
      console.log("删除视频文件：", file);
      console.log("删除后文件列表：", fileList);
    },
    // 图片上传数量超出预设值
    ImgOutOfPreset() {
      console.log(this.ImgPreviewUrl);
    },
    // 视频上传数量超出预设值
    VideoOutOfPreset() {
      console.log(this.ImgPreviewUrl);
    },
    // #endregion
  },
  computed: {
    // 弹窗换行（右侧移到到下面）
    ColSpan() {
      let width = this.WindowSize.width;
      return width <= 1150 ? 24 : 12;
    },
    // 弹窗宽度
    AlertWidth() {
      let width = this.WindowSize.width;
      if (width >= 1800) {
        return "50%";
      } else if (width > 1500 && width <= 1800) {
        return "60%";
      } else if (width > 1300 && width <= 1500) {
        return "70%";
      } else if (width > 700 && width <= 1300) {
        return "80%";
      } else {
        return "100%";
      }
    },
    // 视频播放窗口
    VideoWidth() {
      let width = this.WindowSize.width;
      if (width <= 1000) {
        return "85%";
      } else if (width <= 1100 && width > 1050) {
        return "47%";
      } else if (width <= 1150 && width > 1100) {
        return "45%";
      } else if (width <= 1200 && width > 1150) {
        return "43%";
      } else if (width <= 1250 && width > 1200) {
        return "41%";
      } else if (width <= 1300 && width > 1250) {
        return "39%";
      } else if (width <= 1350 && width > 1300) {
        return "37%";
      } else if (width <= 1400 && width > 1350) {
        return "35%";
      } else if (width <= 1450 && width > 1400) {
        return "33%";
      } else if (width <= 1500 && width > 1450) {
        return "31%";
      } else if (width <= 1550 && width > 1500) {
        return "29%";
      } else if (width <= 1600 && width > 1550) {
        return "27%";
      } else {
        return "25%";
      }
    },
  },
  watch: {
    // 当检测到变化时执行父组件传来的函数
    // 间接实现改变父组件的属性
    RepairFormVisible(newvalue) {
      console.log(newvalue);
      this.ChangeRepairFormVisibleStatus(newvalue);
    },
    GetRepairFormVisible(newvalue) {
      this.RepairFormVisible = newvalue;
    },
  },
  created() {
    this.GetWindowSize();
  },
  mounted() {
    this.GetWindowSize();
  },
  updated() {
    this.GetWindowSize();
  },
};
</script>

<style lang="less">
// 添加报修单
// 上传
.Upload {
  & > .el-upload {
    width: 100px;
    height: 100px;
    position: relative;

    & > i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  & > .el-upload-list > li {
    width: 100px;
    height: 100px;
  }
}

// 隐藏上传图标样式
.hide .el-upload--picture-card {
  display: none;
}

// 预览图片窗口
.Preview {
  display: flex;
  justify-content: center;

  & video,
  & img {
    width: 90%;
    margin-left: 5%;
  }
}
</style>