import { render, staticRenderFns } from "./UserDetail.vue?vue&type=template&id=436666d0&scoped=true&"
import script from "./UserDetail.vue?vue&type=script&lang=js&"
export * from "./UserDetail.vue?vue&type=script&lang=js&"
import style0 from "./UserDetail.vue?vue&type=style&index=0&id=436666d0&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "436666d0",
  null
  
)

export default component.exports