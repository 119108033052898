import axios from "axios";
// 引入路由器
import router from "../router/index";
// 弹窗消息盒子
import { Message } from "element-ui";

// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做一些事情
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 任何位于2xx范围内的状态代码都会触发此函数
    // 对响应数据执行操作
    if (response.data.ActionType === "VisitorAccessDenied") {
      Message({
        type: "error",
        message: "访客不允许修改信息",
      });
    }
    const { authorization } = response.headers;
    // 将token存到本地
    response.data.Token && localStorage.setItem("token", response.data.Token)
    authorization && localStorage.setItem("token", authorization);
    
    return response;
  },
  function (error) {
    // 任何超出2xx范围的状态代码都会触发此函数
    // 处理响应错误
    const { status } = error.response;
    if (status === 401) {
      Message({
        type: "warning",
        message: "登录信息已过期,请重新登录",
      });
      // 清理过期token 跳转页面
      localStorage.removeItem("token");
      localStorage.removeItem("vuex");

      setTimeout(() => {
        router.push({
          path: "/UserLogin",
        });
      }, 1000);
    }

    return Promise.reject(error);
  }
);
