// 该文件专门用于创建整个应用的路由器
import VueRouter from "vue-router";
// 获取原型对象push函数
const OriginalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const OriginalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return OriginalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return OriginalReplace.call(this, location).catch((err) => err);
};
// 以上是为了解决：Navigation Duplicated: Avoided redundant navigation to current location(导航重复: 避免冗余导航到当前位置) 问题

// Main组件
import MainPage from "../pages/MainPage";

// 登录组件
import UserLogin from "../pages/UserLogin";

// 导入子路由组件
import RoutesConfig from "./config";

// 引入vuex
import store from "../store/index";

//创建并暴露一个路由器
const router = new VueRouter({
  // mode: "history",
  routes: [
    // /路径
    {
      path: "/",
      redirect: "HomePage",
    },
    // 登陆页面
    {
      path: "/UserLogin",
      name: "UserLogin",
      component: UserLogin,
      meta: {
        title: "欢迎登录",
      },
    },
    // 用户界面
    {
      path: "/MainPage",
      name: "MainPage",
      component: MainPage,
    },
  ],
});

// 全局前置路由守卫
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (!token && to.name !== "UserLogin") {
    // 用户未登录，但想前往其它组件，则保存想要跳转的路径，并跳转到登录页面
    sessionStorage.setItem("redirect", to.fullPath);
    next({ path: "/UserLogin" });
  } else if (!token && to.path === "/UserLogin") {
    // 用户未登录且跳转路径为登录页面，则放行让其跳转到登录页面
    next();
  } else if (token && to.path === "/UserLogin") {
    // 用户已经登录，但还要前往登录页面，则跳转至登陆前的页面，如果没有则跳转到首页
    next({ path: sessionStorage.getItem("redirect") || "/" });
  } else {
    // 用户已经登录，直接跳转到目标页面
    if (!store.state.IsGetterRouter) {
      LoadingRoute();
      next({
        path: to.fullPath,
      });
    } else {
      next();
    }
  }
    // 调整窗口大小为当前大小加上1像素
	window.resizeTo(window.innerWidth + 1, window.innerHeight + 1);
	// 再次调整窗口大小为当前大小减去1像素
	window.resizeTo(window.innerWidth - 1, window.innerHeight - 1);
});

// 全局后置路由守卫
router.afterEach((to) => {
  document.title = to.meta.title;
});
const LoadingRoute = () => {
  // 循环遍历添加子路由
  RoutesConfig.forEach((item) => {
    router.addRoute("MainPage", item);
  });
  store.commit("ChangeGetterRouter", true);
};
export default router;
