// Home组件
import HomePage from "../pages/HomePage.vue"
// 无权限组件
import NoAuth from "../pages/NoAuth"
// 404组件
import PageNotFound from "../pages/NotFound"

//报修工单
// 全部报修
import AllRepair from "../pages/Main/1_RepairWork/AllRepair"
// 待分配
import ToBeAllocated from "../pages/Main/1_RepairWork/ToBeAllocated"
// 维修中
import IsMainTaining from "../pages/Main/1_RepairWork/IsMainTaining"
// 已完成
import RepairCompleted from "../pages/Main/1_RepairWork/RepairCompleted"

// 我的报修
//全部报修
import MF_AllRepair from "../pages/Main/2_MyFault/MF_AllRepair"
// 未接单
import MF_ToBeAllocated from "../pages/Main/2_MyFault/MF_ToBeAllocated"
// 维修中
import MF_IsMainTaining from "../pages/Main/2_MyFault/MF_IsMainTaining"
// 已完成
import MF_RepairCompleted from "../pages/Main/2_MyFault/MF_RepairCompleted"

// 我的工单
// 我的维护
import MW_Maintenance from "../pages/Main/3_MyWork/MW_Maintenance"
// 维修中
import MW_IsMainTaining from "../pages/Main/3_MyWork/MW_IsMainTaining"
// 已完成
import MW_RepairCompleted from "../pages/Main/3_MyWork/MW_RepairCompleted"

// 考勤签到
// 值班表
import DutyTable from "../pages/Main/4_Attendance/DutyTable"
// 考勤记录
import AttendanceRecord from "../pages/Main/4_Attendance/AttendanceRecord"
// 考勤设置
import AttendanceSettings from "../pages/Main/4_Attendance/AttendanceSettings"

// 用户管理
// 所有用户
import AllUsers from "../pages/Main/5_Personnel/AllUsers"
// 管理员
import AdminUsers from "../pages/Main/5_Personnel/AdminUsers"
// 权限管理
import PermissionManagement from "../pages/Main/5_Personnel/PermissionManagement"

// 仓库管理
// 设备管理
import DeviceManagement from "../pages/Main/6_Warehouse/DeviceManagement"
// 出库管理
import OutboundManagement from "../pages/Main/6_Warehouse/OutboundManagement"
// 租借管理
import LeaseManagement from "../pages/Main/6_Warehouse/LeaseManagement"

// 统计报表
// 工作量统计
import WorkloadStatistics from "../pages/Main/7_Statistical/WorkloadStatistics"

// 系统设置
// 模板设置
import TemplateSettings from "../pages/Main/8_Setting/TemplateSettings"
// 模块设置
import ModuleSettings from "../pages/Main/8_Setting/ModuleSettings"
// 工单设置
import WorkOrderSettings from "../pages/Main/8_Setting/WorkOrderSettings"
// 操作日志
import OperationLog from "../pages/Main/8_Setting/OperationLog"

// 暴露children路由数组
export default [
    // 无权限页面
    {
        path: "/NoAuth",
        name: "NoAuth",
        component: NoAuth,
        meta: {
            title: "您没有权限访问此页面",
        }
    },
    // 主页
    {
        path: "/HomePage",
        name: "HomePage",
        component: HomePage,
        meta: {
            title: "主页",
        }
    },

    // 报修工单
    // 全部报修
    {
        path: "/AllRepair",
        name: "AllRepair",
        component: AllRepair,
        meta: {
            // 标题
            title: "报修工单-全部报修",
            // 权限
            // auth: ""
        }
    },
    // 待分配
    {
        path: "/ToBeAllocated",
        name: "ToBeAllocated",
        component: ToBeAllocated,
        meta: {
            // 标题
            title: "报修工单-待分配",
            // 权限
            // auth: ""
        }
    },
    // 维修中
    {
        path: "/IsMainTaining",
        name: "IsMainTaining",
        component: IsMainTaining,
        meta: {
            // 标题
            title: "报修工单-维修中",
            // 权限
            // auth: ""
        }
    },
    // 已完成
    {
        path: "/RepairCompleted",
        name: "RepairCompleted",
        component: RepairCompleted,
        meta: {
            // 标题
            title: "报修工单-已完成",
            // 权限
            // auth: ""
        }
    },

    // 我的报修
    // 全部报修
    {
        path: "/MF_AllRepair",
        name: "MF_AllRepair",
        component: MF_AllRepair,
        meta: {
            title: "我的报修-全部报修",
            // auth:,
        }
    },
    // 未接单
    {
        path: "/MF_ToBeAllocated",
        name: "MF_ToBeAllocated",
        component: MF_ToBeAllocated,
        meta: {
            title: "我的报修-未接单",
            // auth:,
        }
    },
    // 维修中
    {
        path: "/MF_IsMainTaining",
        name: "MF_IsMainTaining",
        component: MF_IsMainTaining,
        meta: {
            title: "我的报修-维修中",
            // auth:,
        }
    },
    // 已完成
    {
        path: "/MF_RepairCompleted",
        name: "MF_RepairCompleted",
        component: MF_RepairCompleted,
        meta: {
            title: "我的报修-已完成",
            // auth:,
        }
    },

    // 我的工单
    // 我的维护
    {
        path: "/MW_Maintenance",
        name: "MW_Maintenance",
        component: MW_Maintenance,
        meta: {
            title: "我的工单-我的维护",
            // auth:,
        }
    },
    // 维修中
    {
        path: "/MW_IsMainTaining",
        name: "MW_IsMainTaining",
        component: MW_IsMainTaining,
        meta: {
            title: "我的工单-维修中",
            // auth:,
        }
    },
    // 已完成
    {
        path: "/MW_RepairCompleted",
        name: "MW_RepairCompleted",
        component: MW_RepairCompleted,
        meta: {
            title: "我的工单-已完成",
            // auth:,
        }
    },

    // 考勤签到
    // 值班表
    {
        path: "/DutyTable",
        name: "DutyTable",
        component: DutyTable,
        meta: {
            title: "考勤签到-值班表",
            // auth:,
        }
    },
    // 考勤记录
    {
        path: "/AttendanceRecord",
        name: "AttendanceRecord",
        component: AttendanceRecord,
        meta: {
            title: "考勤签到-考勤记录",
            // auth:,
        }
    },
    // 考勤设置
    {
        path: "/AttendanceSettings",
        name: "AttendanceSettings",
        component: AttendanceSettings,
        meta: {
            title: "考勤签到-考勤设置",
            // auth:,
        }
    },

    // 用户管理
    // 所有用户
    {
        path: "/AllUsers",
        name: "AllUsers",
        component: AllUsers,
        meta: {
            title: "用户管理-所有用户",
            // auth:,
        }
    },
    // 管理员
    {
        path: "/AdminUsers",
        name: "AdminUsers",
        component: AdminUsers,
        meta: {
            title: "用户管理-管理员",
            // auth:,
        }
    },
    // 权限管理
    {
        path: "/PermissionManagement",
        name: "PermissionManagement",
        component: PermissionManagement,
        meta: {
            title: "用户管理-权限管理",
            // auth:,
        }
    },

    // 仓库管理
    // 设备管理
    {
        path: "/DeviceManagement",
        name: "DeviceManagement",
        component: DeviceManagement,
        meta: {
            title: "仓库管理-设备管理",
            // auth:,
        }
    },
    // 出库管理
    {
        path: "/OutboundManagement",
        name: "OutboundManagement",
        component: OutboundManagement,
        meta: {
            title: "仓库管理-出库管理",
            // auth:,
        }
    },
    // 租借管理
    {
        path: "/LeaseManagement",
        name: "LeaseManagement",
        component: LeaseManagement,
        meta: {
            title: "仓库管理-租借管理",
            // auth:,
        }
    },

    // 统计报表
    // 工作量统计
    {
        path: "/WorkloadStatistics",
        name: "WorkloadStatistics",
        component: WorkloadStatistics,
        meta: {
            title: "统计报表-维护工作量统计",
            // auth:,
        }
    },

    // 系统设置
    // 模板设置
    {
        path: "/TemplateSettings",
        name: "TemplateSettings",
        component: TemplateSettings,
        meta: {
            title: "系统设置-模板设置",
            // auth:,
        }
    },
    // 模块设置
    {
        path: "/ModuleSettings",
        name: "ModuleSettings",
        component: ModuleSettings,
        meta: {
            title: "系统设置-模块设置",
            // auth:,
        }
    },
    // 工单设置
    {
        path: "/WorkOrderSettings",
        name: "WorkOrderSettings",
        component: WorkOrderSettings,
        meta: {
            title: "系统设置-工单设置",
            // auth:,
        }
    },
    // 操作日志
    {
        path: "/OperationLog",
        name: "OperationLog",
        component: OperationLog,
        meta: {
            title: "系统设置-操作日志",
            // auth:,
        }
    },
    // 404页面(放在路由规则最后面)
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: PageNotFound,
        meta: {
            title: "404 Not Found",
        }
    }
]