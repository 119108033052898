//该文件用于创建Vuex中最为核心的store
import Vue from 'vue'
//引入Vuex
import Vuex from 'vuex'

// 用于数据持久化
import createPersistedstate  from 'vuex-persistedstate'


//应用Vuex插件
Vue.use(Vuex)

//准备state——用于存储数据
const state = {
	// 是否获取路由
	IsGetterRouter: false,
	// 登录用户信息
	UserInfo: {}
}
//准备getters——用于将state中的数据进行加工
const getters = {

}
//准备mutations——用于操作数据（state）
const mutations = {
	ChangeGetterRouter(state, value) {
		state.IsGetterRouter = value
	},
	ChangeUserInfo(state, value) {
		state.UserInfo = {
			...state.UserInfo,
			...value
		}
	}
}
//准备actions——用于响应组件中的动作
const actions = {

}

const modules = {

}
const plugins = [createPersistedstate({
	paths:["UserInfo"]  //控制是否持久化
})]

//创建并暴露store
export default new Vuex.Store({
	actions,
	mutations,
	state,
	getters,
	modules,
	plugins
})