<template>
    <el-row class="MiniPanel">
        <div class="UnitInfo">
            <img src="../../assets/icon/zjipc_blue.png" />
            <div>
                <span id="UnitTitle">网络中心</span>
                <span id="UnitName">浙江工业职业技术学院</span>
            </div>
        </div>
        <div v-if="isTData" class="TodayData">
            <div style="color:#1296DB">
                <div>
                    <img src="../../assets/icon/MiniPanel_All.png"/>
                    <span>全部</span>
                </div>
                <span id="Num">1</span>
            </div>
            <div style="color:#F4EA2A">
                <div>
                    <img src="../../assets/icon/UnTreated.png"/>
                    <span>未处理</span>
                </div>
                <span id="Num">12</span>
            </div>
            <div style="color:#8B58EA">
                <div>
                    <img src="../../assets/icon/Ing.png"/>
                    <span>进行中</span>
                </div>
                <span id="Num">123</span>
            </div>
            <div style="color:#00CA90">
                <div>
                    <img src="../../assets/icon/Finish.png"/>
                    <span>已完成</span>
                </div>
                <span id="Num">333</span>
            </div>
        </div>
    </el-row>
</template>

<script>

export default {
    name: 'MinPanel',
    data:()=>{
        return{
            isTData:true,
        }
    }
}
</script>

<style scoped lang="less">
.MiniPanel {
    width: 100%;
    height: auto;
    padding: 10px;
    border-radius: 15px;
    background-color: #262626;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.6);
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    &>.UnitInfo {
        height: 30%;
        padding: 5px 0;
        display: flex;
        flex-flow: row;
        justify-content: space-evenly;
        align-items: center;
        /*垂直居中*/

        &>img {
            width: 50px;
            height: 50px;
        }

        &>div {
            display: flex;
            flex-flow: column;
            justify-content: space-around;

            &>#UnitTitle {
                height: 24px;
                font-size: 1.1rem;
                font-weight: bold;
                /* 渐变字体颜色 */
                background-image: linear-gradient(to left, #004d7a, #008793, #00bf72, #a8eb12);
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
            }

            &>#UnitName {
                height: 24px;
                line-height: 24px;
                font-size: 0.7rem;
                font-weight: bold;
                /* 渐变字体颜色 */
                background-image: linear-gradient(to left, #004d7a, #008793, #00bf72, #a8eb12);
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
            }
        }
    }

    /* 今日数据 */
    &>.TodayData {
        width: 100%;
        height: 68%;
        margin-top: 5px;
        padding: 5px;
        border: 4px solid #454545;
        border-radius: 15px;
        background-color: #323232;
        display: flex;
        flex-wrap: wrap;

        &>div {
            height: 50%;
            flex-basis: 50%;
            display: flex;
            flex-flow: row;
            justify-content: space-between;

            /* 设置每个盒子的宽度为50% */
            &>div {
                width: 50%;
                display: flex;
                flex-flow: column;
                justify-content: space-evenly;
                align-items: center;

                &>img {
                    width: 20px;
                    height: 20px;
                }

                &>span {
                    width: 36px;
                    text-align: center;
                    font-size: 0.7rem;
                }
            }

            &>span {
                width: 50%;
                height: 3.625rem;
                line-height: 3.625rem;
                text-align: center;
                font-size: 1.1rem;
                font-weight: bold;
            }
        }
    }
}
</style>