<template>
    <div>
        系统设置-操作日志
    </div>
</template>

<script>
export default {
    name: 'OperationLog',
    components: {}
}
</script>
 
<style scoped></style>