<template>
    <div>
        人员管理-权限管理
    </div>
</template>

<script>
export default {
    name: 'PermissionManagement',
    components: {}
}
</script>
 
<style scoped></style>