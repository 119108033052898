<template>
  <el-row class="RepairList">
    <!-- 表单控制区 -->
    <el-row class="FormControlTop" type="flex" justify="space-between">
      <el-col :span="6">
        <slot name="SearchInput"></slot>
      </el-col>
      <el-col :span="4" class="buttons">
        <el-button
          v-if="$route.name === 'AllRepair' || $route.name === 'MF_AllRepair'"
          size="small"
          type="primary"
          @click="OpenAddRepair"
          >添加报修</el-button
        >
        <el-button
          size="small"
          :disabled="DelSelectBtn"
          type="danger"
          @click="DeleteSelect"
          >批量删除</el-button
        >
      </el-col>
    </el-row>

    <!-- 表单区 -->
    <el-table
      v-loading="isLoading"
      :data="GetFaultInfo"
      @selection-change="handleSelectionChange"
      ref="table"
      class="FormInfomation"
      border
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      highlight-selection-row
      :max-height="WindowSize.height - 236"
    >
      <el-table-column fixed type="selection" width="55"></el-table-column>
      <el-table-column
        prop="FaultID"
        label="报修单号"
        width="150"
      ></el-table-column>
      <el-table-column
        v-if="$route.name === 'AllRepair' || $route.name === 'MF_AllRepair'"
        label="工单状态"
        width="120"
      >
        <template slot-scope="WorkOrderStatus">
          <el-tag
            effect="dark"
            :type="getTagType(WorkOrderStatus.row.WorkOrderStatus)"
          >
            {{
              StatusCodeConversion(WorkOrderStatus.row.WorkOrderStatus)
            }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="报修时间" width="200">
        <template slot-scope="StartTime">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px; font-weight: bold">
            {{ formatTime(StartTime.row.StartTime, "Y-M-D h: m: s") }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="UserName"
        label="报修人"
        width="150"
      ></el-table-column>
      <el-table-column prop="UserRole" label="角色" width="120">
        <template slot-scope="UserRole">
          <el-tag
            effect="dark"
            :color="UserRoleColor(UserRole.row.UserRole)"
            type=""
            >{{ UserRole.row.UserRole }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="UserMPhoneNum"
        label="报修人手机号"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="Address"
        label="报修地址"
        width="300"
      ></el-table-column>
      <el-table-column
        prop="FaultDevice"
        label="故障设备"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="FaultState"
        label="故障现象"
        width="120"
      ></el-table-column>
      <el-table-column
        fit="true"
        prop="Images"
        label="故障描述图片"
        width="120px"
        class-name="no-padding"
      >
        <template slot-scope="img">
          <el-image
            v-if="img.row.Images[0]"
            style="width: 50px; height: 50px"
            :src="img.row.Images[0]"
            :preview-src-list="img.row.Images"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="UserIP"
        label="报修IP地址"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="id"
        label="操作"
        width="150"
        min-width="150"
        fixed="right"
      >
        <template slot-scope="id">
          <el-button size="mini" type="pramary" @click="ViewDetails(id.row)"
            >查看</el-button
          >
          <el-popconfirm
            :title="DelTipTitle(id.row)"
            @confirm="Confirm(id.row)"
            @cancel="Cancel(id.row)"
          >
            <el-button slot="reference" size="mini" type="danger"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <slot name="FormControlBottom"></slot>

    <!-- 报修详情 -->
    <RepairDetail
      :DetailList="DetailList"
      :ChangeRepairDetailVisibleStatus="ChangeRepairDetailVisibleStatus"
      :GetRepairDetailVisible="RepairDetailVisible"
    ></RepairDetail>

    <!-- 添加报修 -->
    <AddRepairForm
      :ChangeRepairFormVisibleStatus="ChangeRepairFormVisibleStatus"
      :GetRepairFormVisible="RepairFormVisible"
      :AddressOptions="AddressOptions"
    ></AddRepairForm>
  </el-row>
</template>

<script>
import RepairDetail from "./RepairDetail";
import AddRepairForm from "./AddRepairForm";
export default {
  name: "RepairFormList",
  components: { AddRepairForm, RepairDetail },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    GetFaultInfo: {
      type: Array,
      default: () => [],
    },
    getrepairformlist: {
      type: Function,
    },
  },
  data() {
    return {
      DetailList: {},
      // 添加报修显示
      RepairFormVisible: false,
      // 报修详情显示
      RepairDetailVisible: false,
      AddressOptions: [],

      // window窗口大小监测
      WindowSize: { width: 0, height: 0 },

      // 批量删除按钮禁用状态(默认禁用)
      DelSelectBtn: true,
    };
  },
  methods: {
    // 获取main大小
    GetWindowSize() {
      this.$bus.$on("WindowSize", (data) => {
        console.log();
        this.WindowSize = data;
      });
    },
    // 查看按钮
    ViewDetails(row) {
      this.RepairDetailVisible = true;
      this.RepairDetailTitle = `报修单号：${row.FaultID}`;
      this.DetailList = row;
    },
    // 添加报修显示状态转换
    ChangeRepairFormVisibleStatus(state) {
      this.RepairFormVisible = state;
    },
    // 报修详情显示状态转换
    ChangeRepairDetailVisibleStatus(state) {
      this.RepairDetailVisible = state;
    },
    // 获取报修地址选项信息
    GetAddressInfo() {
      this.axios
        .get("http://112.124.26.92:3001/AddressOptions")
        .then((res) => {
          this.AddressOptions = res.data;
          console.log("请求了一次地址");
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 打开添加报修窗口
    OpenAddRepair() {
      // 如果为空则发起请求获取地址信息
      if (this.AddressOptions.length === 0) {
        this.GetAddressInfo();
      }
      this.RepairFormVisible = true;
    },
    // // 进度状态转换
    // TransitionActiveState() {
    //     // 暂未使用
    // },
    // 时间戳转换
    formatTime(number, format) {
      //转换日期
      var formateArr = ["Y", "M", "D", "h", "m", "s"];
      var returnArr = [];
      var date = new Date(number * 1000);
      returnArr.push(date.getFullYear());
      returnArr.push(this.formatNumber(date.getMonth() + 1));
      returnArr.push(this.formatNumber(date.getDate()));
      returnArr.push(this.formatNumber(date.getHours()));
      returnArr.push(this.formatNumber(date.getMinutes()));
      returnArr.push(this.formatNumber(date.getSeconds()));
      for (var i in returnArr) {
        format = format.replace(formateArr[i], returnArr[i]);
      }
      return format;
    },

    //数据转化时间戳转换用
    formatNumber(n) {
      n = n.toString();
      return n[1] ? n : "0" + n;
    },

    /* // 单击该行后选中该行多选框 
            在table 标签加上 @row-click="handleRowClick"
        handleRowClick(row) {
            this.$refs.table.toggleRowSelection(row);
        }, */

    // 工单状态码转换
    StatusCodeConversion(code) {
      switch (code) {
        case 0:
          return "待分配";
        case 1:
          return "维修中";
        case 2:
          return "已完成";
        default:
          return "未知状态";
      }
    },

    // 工单状态码颜色抓换
    getTagType(code) {
      switch (code) {
        case 0:
          return "danger";
        case 1:
          return "warning";
        case 2:
          return "success";
        default:
          return "info";
      }
    },

    UserRoleColor(role) {
      return role === "学生"
        ? "#2196F3"
        : role === "老师"
        ? "#4CAF50"
        : "#909399";
    },

    // 多选框选择更改时
    handleSelectionChange(selection) {
      // selection包含该行所有信息
      if (selection.length === 0) {
        this.DelSelectBtn = true;
      } else {
        this.DelSelectBtn = false;
      }
    },

    // 删除选中
    DeleteSelect() {
      // 加删除弹窗
      let selections = this.$refs.table.selection;
      if (selections.length != 0) {
        this.$confirm("此操作将删除选中所有选中报修单信息, 是否继续?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "danger",
        })
          .then(() => {
            let deletedCount = 0;
            selections.forEach((item) => {
              this.axios
                .delete(`http://112.124.26.92:3001/RepairList/${item.id}`)
                .then(() => {
                  deletedCount++;
                  if (deletedCount === selections.length) {
                    this.getrepairformlist();
                    this.$message({
                      type: "success",
                      message: `成功删除了${deletedCount}个报修单`,
                    });
                  }
                })
                .catch((err) => {
                  console.error(err);
                  this.$message({
                    type: "danger",
                    message: err,
                  });
                });
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },

    // 删除单个报修单
    // 确认删除
    Confirm(row) {
      this.axios
        .delete(`http://112.124.26.92:3001/RepairList/${row.id}`)
        .then(() => {
          this.getrepairformlist();
          this.$message({
            type: "success",
            message: `已成功删除“${row.UserName}”发起的单号为“${row.FaultID}”的报修单`,
          });
        })
        .catch((err) => {
          console.err(err);
          this.$message({
            type: "danger",
            message: err,
          });
        });
    },

    // 删除提示内容
    DelTipTitle(row) {
      return `确认删除“${row.UserName}”发起的单号为“${row.FaultID}”的报修信息吗？`;
    },
    // 取消删除
    Cancel(row) {
      this.$message({
        type: "info",
        message: `已取消删除“${row.UserName}”的报修单`,
      });
    },

    // 获取当前时间戳
    NowTimeStamp() {
      console.log("获取了一次时间戳");
      return parseInt(new Date().getTime() / 1000);
    },
  },
  computed: {},
  watch: {
    // 格式化选中地址的数据
    SelectAddress(newvalue) {
      console.log(newvalue);
      // console.log(typeof newvalue); //这里判断类型
      if (typeof newvalue === "string") {
        // console.log("是字符串");
        return;
      } else {
        // console.log(newvalue);
        if (newvalue.length === 5) {
          newvalue.splice(1, 1);
        }
        this.AddRepairInfo.Address = newvalue.join("-");
        console.log(this.SelectAddress); //检验是否更改成功
        console.log(this.AddRepairInfo.Address); //检验是否更改成功
      }
    },
  },
  created() {
    this.GetWindowSize();
  },
  mounted() {
    this.GetWindowSize();
  },
  updated() {
    this.GetWindowSize();
  },
};
</script>

<style lang="less">
@BGC-day-dark: #262626;
@BGC-day-Light: #323232;

// 表单操作区
.FormControlTop {
  height: 50px;
  padding: 0 10px 0 10px;
  border-radius: 5px 5px 0 0;
  // 下面这行删除
  background-color: @BGC-day-Light;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & .el-select .el-input {
    margin-left: 5px;
    width: 110px;
  }

  & .input-with-select > .el-input__inner {
    min-width: 220px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

// 表单区
.FormInfomation {
  width: 100%;

  & .no-padding {
    padding: 0 !important;
  }

  & .cell {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
  }
}
</style>