import Vue from "vue";
Vue.config.productionTip = false;
import App from "./App.vue";

import "../theme/index.css";
// 发请求
import axios from "axios";
import VueAxios from "vue-axios";

// 引入路由插件
import VueRouter from "vue-router";
// 引入路由器
import router from "./router";

// 随机id
import { nanoid } from "nanoid";
Vue.prototype.nanoid = nanoid;

import {
  Calendar,
  PageHeader,
  Empty,
  Upload,
  Cascader,
  Checkbox,
  Form,
  FormItem,
  Timeline,
  TimelineItem,
  Tabs,
  TabPane,
  Descriptions,
  DescriptionsItem,
  Dialog,
  Popconfirm,
  Image,
  Tag,
  Pagination,
  Container,
  Aside,
  Header,
  Main,
  Footer,
  Avatar,
  Popover,
  Button,
  Divider,
  Link,
  Row,
  Col,
  Input,
  Select,
  Option,
  Table,
  TableColumn,
  DatePicker,
  TimePicker,
  CheckboxGroup,
  Statistic
} from "element-ui";
Vue.component("el-statistic", Statistic);
Vue.component("el-checkbox-group", CheckboxGroup);
Vue.component("el-time-picker", TimePicker);
Vue.component("el-date-picker", DatePicker);
Vue.component("el-container", Container);
Vue.component("el-aside", Aside);
Vue.component("el-header", Header);
Vue.component("el-main", Main);
Vue.component("el-footer", Footer);
Vue.component("el-avatar", Avatar);
Vue.component("el-popover", Popover);
Vue.component("el-button", Button);
Vue.component("el-divider", Divider);
Vue.component("el-link", Link);
Vue.component("el-row", Row);
Vue.component("el-input", Input);
Vue.component("el-select", Select);
Vue.component("el-option", Option);
Vue.component("el-col", Col);
Vue.component("el-table", Table);
Vue.component("el-table-column", TableColumn);
Vue.component("el-pagination", Pagination);
Vue.component("el-tag", Tag);
Vue.component("el-image", Image);
Vue.component("el-popconfirm", Popconfirm);
Vue.component("el-dialog", Dialog);
Vue.component("el-descriptions", Descriptions);
Vue.component("el-descriptions-item", DescriptionsItem);
Vue.component("el-tabs", Tabs);
Vue.component("el-tab-pane", TabPane);
Vue.component("el-timeline", Timeline);
Vue.component("el-timeline-item", TimelineItem);
Vue.component("el-form", Form);
Vue.component("el-form-item", FormItem);
Vue.component("el-checkbox", Checkbox);
Vue.component("el-cascader", Cascader);
Vue.component("el-upload", Upload);
Vue.component("el-empty", Empty);
Vue.component("el-page-header", PageHeader);
Vue.component("el-calendar", Calendar);

// 加载插件引入
import { Loading } from "element-ui";
Vue.use(Loading.directive);

// 弹窗消息盒子
import { MessageBox, Message } from "element-ui";
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;

// 引入全局方法
// 节流方法,防抖方法
import { throttle, debounce } from "./GlobalFunction/main";
Vue.prototype.$throttle = throttle;
Vue.prototype.$debounce = debounce;

Vue.use(VueAxios, axios);
Vue.use(VueRouter);

// 引入axios拦截器
import "@/util/axios.config";

//引入store
import store from "./store";

new Vue({
  render: (h) => h(App),
  router: router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this; //安装全局事件总线
  },
}).$mount("#app");
