<template>
  <!-- 我的报修-全部报修 -->
  <RepairFormList
    :isLoading="isLoading"
    :GetFaultInfo="FaultInfo"
    :getrepairformlist="GetRepairInfo"
  >
    <el-row class="FormControlBottom" slot="FormControlBottom">
      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pagesize"
        layout=" total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-row>
  </RepairFormList>
</template>
  
  <script>
import RepairFormList from "../RepairFormList";
export default {
  name: "MF_IsMainTaining",
  components: { RepairFormList },
  data() {
    return {
      isLoading: false,
      // 筛选框
      keywords: "",
      keytype: "",

      // 分页器
      total: 0,
      pagesize: 11,
      currentPage: 1,

      // 报修单数据
      FaultInfo: [],
    };
  },
  computed: {
    placeholder() {
      switch (this.keytype) {
        case "1":
          return "请输入完整姓名";
        case "2":
          return "请输入完整学号/工号";
        case "3":
          return "请输入完整手机号码";
        case "4":
          return "请输入完整电话号码";
        default:
          return "模糊查询";
      }
    },
  },
  methods: {
    // 获取报修单总数量
    GetRepaircount() {
      this.axios
        .get(
          `http://112.124.26.92:3001/RepairList/count?UserID=${this.$store.state.UserInfo.UserID}`
        )
        .then((response) => {
          this.total = response.data.count;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取报修单信息
    GetRepairInfo() {
      this.isLoading = true;
      this.GetRepaircount();
      this.axios
        .get(
          `http://112.124.26.92:3001/RepairList?UserID=${this.$store.state.UserInfo.UserID}&_sort=WorkOrderStatus&_order=asc&_page=${this.currentPage}&_limit=${this.pagesize}`
        )
        .then((response) => {
          this.FaultInfo = response.data;
          console.log("请求服务器工单列表");
          this.isLoading = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleCurrentChange(currentpage) {
      // 当前页码发生变化
      this.currentPage = currentpage;
      this.GetRepairInfo();
    },
    
    // 报修表单
    // 时间戳转换
    formatTime(number, format) {
      //转换日期
      var formateArr = ["Y", "M", "D", "h", "m", "s"];
      var returnArr = [];
      var date = new Date(number * 1000);
      returnArr.push(date.getFullYear());
      returnArr.push(this.formatNumber(date.getMonth() + 1));
      returnArr.push(this.formatNumber(date.getDate()));
      returnArr.push(this.formatNumber(date.getHours()));
      returnArr.push(this.formatNumber(date.getMinutes()));
      returnArr.push(this.formatNumber(date.getSeconds()));
      for (var i in returnArr) {
        format = format.replace(formateArr[i], returnArr[i]);
      }
      return format;
    },
    //数据转化
    formatNumber(n) {
      n = n.toString();
      return n[1] ? n : "0" + n;
    },

    /* // 单击该行后选中该行多选框 
              在table 标签加上 @row-click="handleRowClick"
          handleRowClick(row) {
              this.$refs.table.toggleRowSelection(row);
          }, */

    // 工单状态码转换
    StatusCodeConversion(code) {
      switch (code) {
        case 0:
          return "待分配";
        case 1:
          return "维修中";
        case 2:
          return "已完成";
        default:
          return "未知状态";
      }
    },
    // 工单状态码颜色抓换
    getTagType(code) {
      switch (code) {
        case 0:
          return "danger";
        case 1:
          return "warning";
        case 2:
          return "success";
        default:
          return "info";
      }
    },

    // 删除选中
    DeleteSelect() {
      // 加删除弹窗
      let selections = this.$refs.table.selection;
      this.$confirm("此操作将删除选中所有选中报修单信息, 是否继续?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "danger",
      })
        .then(() => {
          selections.forEach((item) => {
            this.axios
              .delete(`http://112.124.26.92:3001/RepairList/${item.id}`)
              .then((response) => {
                this.FaultInfo = response.data;
                this.getrepairformlist();
                this.$message({
                  type: "success",
                  message: `成功删除了${selections.length}个报修单`,
                });
              })
              .catch((err) => {
                console.error(err);
                this.$message({
                  type: "danger",
                  message: err,
                });
              });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  mounted() {
    this.GetRepairInfo();
  },
  activated() {
    // AllRepair组件被激活了
  },
  deactivated() {
    // AllRepair组件失活了
  },
};
</script>
  
  <style scoped lang="less">
@BGC-day-dark: #262626;
@BGC-day-Light: #323232;

.FormControlBottom {
  height: 50px;
  padding: 0 10px 0 10px;
  border-radius: 0 0 5px 5px;
  // 下面这行删除
  background-color: @BGC-day-Light;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>