<template>
  <div class="Home">
    <div class="top">
      <el-row class="title">所有工单</el-row>
      <el-row class="content">
        <el-col class="items" :span="4">
          <div>全部工单</div>
          <div class="data">2</div>
          <div>今日0</div>
        </el-col>
        <el-col class="items" :span="4">
          <div>未处理</div>
          <div class="data">2</div>
          <div>今日0</div>
        </el-col>
        <el-col class="items" :span="4">
          <div>进行中</div>
          <div class="data">2</div>
          <div>今日0</div>
        </el-col>
        <el-col class="items" :span="4">
          <div>已完成</div>
          <div class="data">2</div>
          <div>今日0</div>
        </el-col>
        <el-col class="items" :span="4">
          <div>已关闭</div>
          <div class="data">2</div>
          <div>今日0</div>
        </el-col>
        <el-col class="items" :span="4">
          <div>超时单</div>
          <div class="data">2</div>
          <div>今日0</div>
        </el-col>
      </el-row>
    </div>
    <div class="bottom">
      <el-row class="title">工单排行榜</el-row>
      <el-row class="content">
        <el-empty :image-size="200"></el-empty>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  components: {},
};
</script>
 
<style scoped lang="less">
.Home {
  width: 100%;
  height: 100%;
  background-color: #323232;
  color: #323232;
  border-radius: 5px;
  padding: 15px;
  & > .top {
    width: 100%;
    height: 29%;
    min-height: 200px;
    background-color: #fff;
    border-radius: 10px;
    & > .title {
      height: 50px;
      line-height: 50px;
      padding: 0 15px;
      font-size: 22px;
      font-weight: bolder;
    }
    & > .content {
      & > .items {
        height: 150px;
        text-align: center;
        display: flex;
        flex-flow: column;
        justify-content: space-evenly;
        color: #878787;
        & > .data {
          font-size: 30px;
          color: #262626;
        }
      }
    }
  }
  & > .bottom {
    width: 100%;
    height: 69%;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 1%;
    & > .title {
      height: 50px;
      line-height: 50px;
      padding: 0 15px;
      font-size: 22px;
      font-weight: bolder;
    }
    &>.content{
        
    }
  }
}
</style>