<template>
  <el-container>
    <el-aside width="220px">
      <MinPanel></MinPanel>
      <MenuItems></MenuItems>
    </el-aside>
    <el-container>
      <el-header height="66px">
        <HeadIcon></HeadIcon>
      </el-header>
      <el-main>
        <!-- 保持激活状态 会生成两个新的生命周期钩子activated(激活时) deactivated(失活时)-->
        <!-- <keep-alive> -->
        <router-view></router-view>
        <!-- </keep-alive> -->
      </el-main>
      <el-footer height="40px">
        <CopyRight></CopyRight>
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>
import MinPanel from "../components/Left/MinPanel";
import MenuItems from "../components/Left/MenuItem";
import HeadIcon from "../components/Header/HeadIcon";
import CopyRight from "../components/Footer/CopyRight";
export default {
  name: "MainPage",
  components: { MinPanel, MenuItems, HeadIcon, CopyRight },
  data() {
    return {};
  },
  methods: {
    // 浏览器窗口大小
    WindowSize() {
      let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;
      localStorage.setItem(
        "WindowsSize",
        JSON.stringify({ width: windowWidth, height: windowHeight })
      );
      this.$bus.$emit("WindowSize", {
        width: windowWidth,
        height: windowHeight,
      });
    },
  },
  mounted() {
    this.WindowSize();
    window.addEventListener("resize", this.$debounce(this.WindowSize, 1));
  },
  beforeDestroy() {
    // 组件销毁前移除Main窗口监听
    window.removeEventListener("resize", this.$debounce(this.WindowSize, 1));
  },
};
</script>

<style lang="less">
@BGC-day-dark: #262626;
@BGC-day-Light: #323232;

/* 统一组件样式 */
* {
  box-sizing: border-box;
  list-style: none;
  margin: 0px;
  padding: 0px;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
}

.el-container {
  height: 100%;
  color: #cccccc;
  .el-header,
  .el-footer {
    background-color: @BGC-day-Light;
  }

  .el-footer {
    text-align: center;
    line-height: 40px;
  }

  .el-aside {
    padding: 12px 10px;
    background-color: @BGC-day-Light;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }

  .el-main {
    padding: 15px;
    background-color: @BGC-day-dark;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.6);

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }

  /* 主屏幕最小宽度 */
  .el-header,
  .el-main,
  .el-footer {
    min-width: 620px;
  }
}

/* 全局样式 */
.root {
  background-color: #fd5d5d;
  color: #ffffff;
  font-weight: 900;
}

.admin {
  background-color: #63d563;
  color: @BGC-day-Light;
  font-weight: 900;
}

.engineer {
  background-color: #e6a23c;
  color: @BGC-day-Light;
  font-weight: 900;
}

.ordinary {
  background-color: #6558f4;
  color: #ffffff;
  font-weight: 900;
}

.teacher {
  background-color: #4caf50;
  color: #ffffff;
  font-weight: 900;
}

.student {
  background-color: #2196f3;
  color: @BGC-day-Light;
  font-weight: 900;
}

.other {
  background-color: #909399;
  color: #ffffff;
  font-weight: 900;
}
</style>